const columnsAproveCommerce = [
  {
    name: 'name',
    label: 'Razón Social',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'fantasyName',
    label: 'Fantasía',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'taxId',
    label: 'CUIT',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdBy',
    label: 'Comercial',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Estado',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'createdDate',
    label: 'Fecha Alta',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export default columnsAproveCommerce;
