import i18n from '../components/common/i18n';

export const statusMapper = (originalStatus) => {
  let modifiedStatus = '';
  switch (originalStatus) {
    case i18n.commercePreApproved:
      modifiedStatus = i18n.commercePreApprovedSpanish;
      break;
    case i18n.commerceApproved:
      modifiedStatus = i18n.commerceApprovedSpanish;
      break;
    case i18n.commerceRejected:
      modifiedStatus = i18n.commerceRejectedSpanish;
      break;
    case i18n.commerceInitiated:
      modifiedStatus = i18n.commerceInitiatedSpanish;
      break;
    case i18n.commerceCompleted:
      modifiedStatus = i18n.commerceCompletedSpanish;
      break;
    case i18n.commerceFinished:
      modifiedStatus = i18n.commerceFinishedSpanish;
      break;
    case i18n.commercePreApprovedSpanish:
      modifiedStatus = i18n.commercePreApproved;
      break;
    case i18n.commerceApprovedSpanish:
      modifiedStatus = i18n.commerceApproved;
      break;
    case i18n.commerceRejectedSpanish:
      modifiedStatus = i18n.commerceRejected;
      break;
    case i18n.commerceInitiatedSpanish:
      modifiedStatus = i18n.commerceInitiated;
      break;
    case i18n.commerceCompletedSpanish:
      modifiedStatus = i18n.commerceCompleted;
      break;
    case i18n.commerceFinishedSpanish:
      modifiedStatus = i18n.commerceFinished;
      break;
    case i18n.filterCommerceViewAll:
      modifiedStatus = i18n.filterCommerceViewAll;
      break;
    case i18n.commerceStatusChanged:
      modifiedStatus = i18n.commerceStatusChangedSpanish;
      break;
    case i18n.commerceException:
      modifiedStatus = i18n.commerceExceptionSpanish;
      break;
    case i18n.detailsDecisionEngineUser:
      modifiedStatus = i18n.detailsDecisionEngineSpanish;
      break;
    default:
      modifiedStatus = '';
      break;
  }

  return modifiedStatus;
};

export const branchStatusMapper = (originalStatus) => {
  switch (originalStatus) {
    case i18n.branchCreated: return i18n.branchCreatedSpanish;
    case i18n.branchCompleted: return i18n.branchCompletedSpanish;
    case i18n.branchFinalized: return i18n.branchFinalizedSpanish;
    case i18n.branchCreatedSpanish: return i18n.branchCreated;
    case i18n.branchCompletedSpanish: return i18n.branchCompleted;
    case i18n.branchFinalizedSpanish: return i18n.branchFinalized;
    default: return '';
  }
};
