import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import i18n from '../../components/common/i18n';
import TextFieldWrapper from '../../components/common/TextFieldWrapper';
import Loading from '../../components/common/Loading';
import LoadingButton from '../../components/common/LoadingButton';
import { createUser, updateUser, deleteUser } from '../../api/services';
import { translateErrorOrDefault } from '../../utils/functionsUtil';
import { userSchemaValidation } from '../../forms/schemaValidations';

const branchBoxManagerData = ({
  managerDataSuccess, setManagerDataSuccess, managerNameField, managerLastNameField,
  managerDocumentNumberField, managerPhoneField, managerEmailField, closeForm, init,
}) => {
  const { idBranch } = useParams();
  const [isEditable, setIsEditable] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataSavedError, setDataSavedError] = useState(undefined);
  const [dataDeletedError, setDataDeletedError] = useState(undefined);
  const [documentNumber, setDocumentNumber] = useState();

  const createNewManager = async (data) => {
    try {
      const request = {
        name: data.name?.toUpperCase(),
        lastName: data.lastName?.toUpperCase(),
        document: data.documentNumber,
        phone: data.phone,
        mail: data.email,
        role: 'MANAGER',
      };
      await createUser(request, idBranch);
      setDocumentNumber(data.documentNumber);
      setIsEditable(false);
      setManagerDataSuccess(true);
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
  };

  const updateManager = async (data) => {
    try {
      const request = {
        name: data.name?.toUpperCase(),
        lastName: data.lastName?.toUpperCase(),
        phone: data.phone,
        mail: data.email,
        role: 'MANAGER',
      };
      await updateUser(request, idBranch, data.documentNumber);
      setIsEditable(false);
      setManagerDataSuccess(true);
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
  };

  const deleteManager = async () => {
    try {
      await deleteUser(idBranch, documentNumber);
      setIsEditable(false);
      setManagerDataSuccess(false);
      init();
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataDeletedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorDeleteData));
    }
  };

  const onClickSubmit = async (data) => {
    setDataSavedError(undefined);
    setIsSaving(true);

    if (!managerDataSuccess) {
      await createNewManager(data);
    } else {
      await updateManager(data);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    setIsEditable(!managerDataSuccess);
    setDocumentNumber(managerDocumentNumberField);
  }, []);

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          name: managerDataSuccess ? managerNameField : '',
          lastName: managerLastNameField,
          documentNumber: managerDocumentNumberField,
          phone: managerPhoneField,
          email: managerEmailField,
        }}
        validationSchema={userSchemaValidation}
        onSubmit={(data) => {
          if (isEditable) {
            onClickSubmit(data);
          } else {
            setIsEditable(true);
          }
        }}
      >
        {({ isValid }) => (
          <>
            <Form>
              {isSaving === true && (
                <Loading />
              )}
              {isSaving === false && (
              <>
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  required
                  name="name"
                  label={i18n.formCommerceName}
                  autoComplete={i18n.formCommerceName}
                  disabled={!isEditable}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  required
                  name="lastName"
                  label={i18n.formCommerceLastName}
                  autoComplete={i18n.formCommerceLastName}
                  disabled={!isEditable}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  required
                  name="documentNumber"
                  inputProps={{ maxLength: 8 }}
                  label={i18n.formCommerce_documentNumber}
                  autoComplete={i18n.formCommerce_documentNumber}
                  disabled={!isEditable || managerDataSuccess}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  required
                  name="phone"
                  label={i18n.formCommerce_cellphone}
                  autoComplete={i18n.formCommerce_cellphone}
                  inputProps={{ maxLength: 12 }}
                  disabled={!isEditable}
                  helperText={i18n.formCommerce_cellphoneHelper}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  required
                  name="email"
                  label={i18n.formCommerce_email}
                  autoComplete={i18n.formCommerce_email}
                  disabled={!isEditable}
                />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
                  }}
                  type="submit"
                  disabled={!(isEditable) && !(isValid)}
                  loading={isSaving}
                >
                  {isEditable ? i18n.formCommerceButtonSave : i18n.formCommerceButtonEdit}
                </LoadingButton>
                  {dataSavedError && (
                  <Alert severity="error">
                    {dataSavedError}
                  </Alert>
                  )}
              </>
              )}
            </Form>
            <LoadingButton
              variant="contained"
              color="primary"
              style={{
                margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
              }}
              type="submit"
              disabled={!managerDataSuccess}
              loading={isSaving}
              onPress={deleteManager}
            >
              {i18n.listBranchesDelete}
            </LoadingButton>
            {dataDeletedError && (
              <Alert severity="error">
                {dataDeletedError}
              </Alert>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

branchBoxManagerData.propTypes = {
  managerDataSuccess: PropTypes.bool.isRequired,
  setManagerDataSuccess: PropTypes.func.isRequired,
  managerNameField: PropTypes.string,
  managerLastNameField: PropTypes.string,
  managerDocumentNumberField: PropTypes.string,
  managerPhoneField: PropTypes.string,
  managerEmailField: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
};
branchBoxManagerData.defaultProps = {
  managerNameField: '',
  managerLastNameField: '',
  managerDocumentNumberField: '',
  managerPhoneField: '',
  managerEmailField: '',
};

export default branchBoxManagerData;
