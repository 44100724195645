import { useEffect, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { useHistory, useParams } from 'react-router-dom';
import {
  getBranchRequestAfilliationList, resolveSappCustomer, getStates, saveBranchInSapp,
} from '../api/services';
import Loading from '../components/common/Loading';
import BranchDetailsUserData from '../components/BranchDetailsUserData';
import i18n from '../components/common/i18n';
import { useUserData } from '../contexts/userDataContext';
import { route } from '../constants/routes';
import { fillMessageWith, translateErrorOrDefault } from '../utils/functionsUtil';
import { branchStatusMapper } from '../utils/statusMapper';

const branchDetails = () => {
  const { idBranch } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [branchData, setBranchData] = useState(undefined);
  const [commerceName, setCommerceName] = useState('');
  const [province, setProvince] = useState('');
  const [managerData, setManagerData] = useState(undefined);
  const [sellersData, setSellersData] = useState(undefined);
  const history = useHistory();
  // const [branchStatus, setBranchStatus] = useState('');
  const { userData } = useUserData();
  const [rol] = useState(userData.roles);
  const [showButtonSaveInSapp, setShowButtonSaveInSapp] = useState(false);
  const [showSuccessSaveInSapp, setShowSuccessSaveInSapp] = useState(false);
  const [detailsSaveInSappError, setDetailsSaveInSappError] = useState(undefined);

  const provinceMapper = async (idProvince) => {
    const statesResponse = await getStates();
    if (statesResponse.data.length > 0) {
      for (let i = 0; i < statesResponse.data.length; i += 1) {
        if (statesResponse.data[i].id === idProvince) {
          setProvince(statesResponse.data[i].descripcion);
        }
      }
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    try {
      const { data } = await getBranchRequestAfilliationList(idBranch);
      const commerceData = await resolveSappCustomer(data.headOfficeTaxId);
      setCommerceName(commerceData.data.razonSocial);
      setBranchData(data);
      setManagerData(data.userList?.filter((user) => user.role.includes('MANAGER')));
      setSellersData(data.userList?.filter((user) => user.role.includes('SELLER')));
      provinceMapper(data.province);
      setRefreshPage(false);
      setShowButtonSaveInSapp(data.status === i18n.branchCompleted && rol.includes(i18n.rolAdmin));
      setShowSuccessSaveInSapp(data.status === i18n.branchFinalized);
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response.status === 404) {
        history.push(route.listBranches);
      }
    }
    setIsLoading(false);
  }, [refreshPage]);

  const onClickSaveInSapp = async () => {
    setIsLoading(true);
    try {
      const request = {
        requestedBy: userData.sub,
      };
      await saveBranchInSapp(idBranch, request);
      setShowButtonSaveInSapp(false);
      setShowSuccessSaveInSapp(true);
    } catch (error) {
      console.error('There was an error!', error);
      setDetailsSaveInSappError(translateErrorOrDefault(error.response.data?.code,
        fillMessageWith(i18n.COMMERCE_AFFILIATION_REQUEST_ERROR_SAVING_IN_SAPP, error.response.data?.message)));
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && branchData && (
        <Box mt={8}>
          <Grid container>
            <Grid item xs={1} md={2} />
            <Grid item xs={10} md={8}>
              <Box pb={5} style={{ border: '1px solid #b1a7a6' }}>
                <Box mt={5}>
                  <Typography variant="h3">{i18n.detailsBranchTitle}</Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12} md={6} className="textLeft">
                    <Box ml={5}>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsBussinessName}
                          <strong>{commerceName}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsName}
                          <strong>{branchData.fantasyName}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsCuit}
                          <strong>{branchData.headOfficeTaxId}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsEmail}
                          <strong>{branchData.mail}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsPhone}
                          <strong>{branchData.phone}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsState}
                          <strong>{branchStatusMapper(branchData.status)}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} className="textLeft">
                    <Box mt={5}>
                      <Typography variant="h5">
                        {i18n.detailsProvince}
                        <strong>{province}</strong>
                      </Typography>
                    </Box>
                    <Box mt={5}>
                      <Typography variant="h5">
                        {i18n.detailsTown}
                        <strong>{branchData.location}</strong>
                      </Typography>
                    </Box>
                    <Box mt={5}>
                      <Typography variant="h5">
                        {i18n.detailsAddressName}
                        <strong>{branchData.street}</strong>
                      </Typography>
                    </Box>
                    <Box mt={5}>
                      <Typography variant="h5">
                        {i18n.detailsAddressNumber}
                        <strong>{branchData.number}</strong>
                      </Typography>
                    </Box>
                    <Box mt={5}>
                      <Typography variant="h5">
                        {i18n.detailsPostalCode}
                        <strong>{branchData.zipCode}</strong>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              { /* Detalles encargado de sucursal */ }
              {
                managerData && (
                  <Box mt={10} mb={10} pb={5} style={{ border: '1px solid #b1a7a6' }}>
                    <Box mt={5}>
                      <Typography variant="h3">{i18n.detailsBranchManagerTitle}</Typography>
                    </Box>
                    {
                    managerData[0] !== undefined && (
                    <BranchDetailsUserData userData={managerData[0]} />
                    )
                    }
                  </Box>
                )
              }

              { /* Detalles vendedores */ }
              {
                sellersData && (
                  <Box mt={10} mb={10} pb={5} style={{ border: '1px solid #b1a7a6' }}>
                    <Box mt={5}>
                      <Typography variant="h3">{i18n.detailsSellersTitle}</Typography>
                    </Box>
                    {sellersData.map((seller) => (
                      <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                          <Box mt={6} pb={5} style={{ border: '1px solid #b1a7a6' }}>
                            <BranchDetailsUserData userData={seller} />
                          </Box>
                        </Grid>
                        <Grid item xs={1} />
                      </Grid>
                    ))}
                  </Box>
                )
              }
            </Grid>
            <Grid item xs={1} md={2} />
          </Grid>
          {detailsSaveInSappError ? (
            <Box mb={5}>
              <Grid container>
                <Grid item xs={1} md={2} />
                <Grid item xs={10} md={8}>
                  <Alert severity="error">
                    {detailsSaveInSappError}
                  </Alert>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Box>
          ) : showButtonSaveInSapp ? (
            <Box mb={5}>
              <Grid container>
                <Grid item xs={1} md={2} />
                <Grid item xs={10} md={8}>
                  <Button variant="contained" onClick={onClickSaveInSapp}>{i18n.detailsSaveInSapp}</Button>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Box>
          ) : (
            showSuccessSaveInSapp && (
              <Box mt={5} mb={5}>
                <Grid container>
                  <Grid item xs={1} md={2} />
                  <Grid item xs={10} md={8}>
                    <Alert severity="success">
                      {i18n.detailsSaveInSappSuccess}
                    </Alert>
                  </Grid>
                  <Grid item xs={1} md={2} />
                </Grid>
              </Box>
            )
          ) }
        </Box>
      )}
    </>
  );
};

export default branchDetails;
