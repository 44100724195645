import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import LoadingButton from './LoadingButton';
import i18n from './i18n';

const useStyles = makeStyles(() => ({
  containerSignatureBox: {
    position: 'relative',
    border: 'solid 1px',
    maxWidth: '100%',
    maxHeight: '302px',
  },
  signatureBox: {
    width: '100%',
    height: '100%',
    maxWidth: '500px',
    maxHeight: '300px',
  },
  cleanSignatureBox: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    background: 'rgba(73,144,226,.6)',
    margin: '0',
    padding: '0',
  },
  cleanSignatureButton: {
    color: '#fff',
    textDecoration: 'underline',
    padding: '1em',
    margin: '0',
    width: '100%',
  },
}));

const SignatureBox = ({ signatureBoxRef, submitFunction, loading }) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);

  const verifySignature = () => {
    setIsDisabled(signatureBoxRef.current.isEmpty());
  };

  const clear = () => {
    if (signatureBoxRef.current.toData().length > 0) {
      signatureBoxRef.current.clear();
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (signatureBoxRef.current.isEmpty && signatureBoxRef.current.canvas) {
      // Se elimina al iniciar porque useEffect se ejecuta multiples veces (al menos 2)
      signatureBoxRef.current.canvas.current.removeEventListener('click', () => verifySignature());
      signatureBoxRef.current.canvas.current.addEventListener('click', () => verifySignature());
      signatureBoxRef.current.canvas.current.removeEventListener('touchstart', () => verifySignature());
      signatureBoxRef.current.canvas.current.addEventListener('touchstart', () => verifySignature());
      clear();
    }
  }, []);

  return (
    <div>
      <div className={classes.containerSignatureBox}>
        <SignaturePad className={classes.signatureBox} ref={signatureBoxRef} redrawOnResize />
        <div className={classes.cleanSignatureBox}>
          <Button
            className={classes.cleanSignatureButton}
            onClick={clear}
            disabled={isDisabled}
          >
            {i18n.clearSignature}
          </Button>
        </div>

      </div>
      <LoadingButton
        variant="contained"
        color="primary"
        style={{
          margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
        }}
        onPress={submitFunction}
        loading={loading}
        disabled={isDisabled}
      >
        {i18n.formCommerceButtonSignAndSend}
      </LoadingButton>
    </div>
  );
};

SignatureBox.propTypes = {
  signatureBoxRef: PropTypes.objectOf(Object).isRequired,
  submitFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SignatureBox;
