import { Link } from 'react-router-dom';
import {
  Grid, makeStyles, useMediaQuery,
} from '@material-ui/core';
import i18n from './common/i18n';
import NavBarMobile from './NavBarMobile';
import logoCredicuotas from '../logoCredicuotas.png';
import menuOptions from '../constants/navBarRoutes';
import { saveCurrentUserData } from '../utils/userStorage';
import { useUserData } from '../contexts/userDataContext';

const useStyles = makeStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0px 0px 10px #d6d6d6',
    position: 'relative',
    borderBottom: '1px solid #D6D6D6',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
});
const NavBar = () => {
  const classes = useStyles();
  const matchesMobile = useMediaQuery('(max-width: 862px)');
  const { setUserData } = useUserData();

  const signOutHandler = () => {
    setUserData({});
    saveCurrentUserData({});
  };

  return (
    <Grid container className={classes.header} alignContent="space-between" alignItems="center">
      <Grid item xs={3}>
        <img
          src={logoCredicuotas}
          className="logo-cc"
          alt=""
          width="200px"
          style={{ float: 'left', marginLeft: '25px' }}
        />
      </Grid>
      <Grid item className="login-menu" xs={9}>

        {
          matchesMobile
            ? (
              <div style={{ float: 'right', marginRight: '10px' }}><NavBarMobile /></div>
            )
            : (
              <div>
                <ul>
                  {menuOptions.map((menuItem) => (
                    <li key={menuItem.label}>
                      <Link
                        to={menuItem.path}
                      >
                        {menuItem.label}
                      </Link>
                    </li>
                  ))}
                  <li key={i18n.menuOptionExit}>
                    <Link href="#/" onClick={signOutHandler}>
                      {i18n.menuOptionExit}
                    </Link>
                  </li>
                </ul>
              </div>
            )
        }

      </Grid>
    </Grid>
  );
};

export default NavBar;
