import { Button } from '@material-ui/core';
import i18n from '../components/common/i18n';

const welcomeBranches = () => {
  console.log('bienvenida a sucursales');
  return (
    <Button variant="contained">
      {i18n.welcomeBranchesManage}
    </Button>
  );
};

export default welcomeBranches;
