import { Box, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, Collapse, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import {
  requestException,
  updateCommerceRequestAfilliationStatus,
} from '../api/services';
import { useUserData } from '../contexts/userDataContext';
import i18n from './common/i18n';
import Toast from './common/Toast';

const changeStateBox = ({ refresh, commerceId }) => {
  const { userData } = useUserData();
  const [rol] = useState(userData.roles);
  const [submitType, setSubmitType] = useState('');
  const [motivo, setMotivo] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [charactersException, showCharactersException] = useState(false);

  const changeMotiveHandler = (e) => {
    setMotivo(e.target.value);
  };

  const approveHandler = async () => {
    try {
      await updateCommerceRequestAfilliationStatus(commerceId, submitType, motivo);
      setMotivo('');
    } catch (error) {
      console.error('There was an error!', error);
    }
    refresh(true);
  };

  const exceptionHandler = async () => {
    try {
      setIsLoading(true);
      await requestException(commerceId, motivo);
      setMotivo('');
      refresh(true);
    } catch (error) {
      console.error('There was an error!', error.response.data);
      if (error.response.data.code === 'COMMERCE_AFFILIATION_REQUEST_EXCEPTION_ALREADY_REQUESTED') {
        setErrorMessage(i18n.detailsExceptionAlreadyRequestedMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formActionHandler = (e) => {
    e.preventDefault();
    if ((submitType === i18n.commerceApproved) || (submitType === i18n.commerceRejected)) {
      approveHandler();
    } else if (motivo.length < 20) {
      showCharactersException(true);
    } else {
      showCharactersException(false);
      exceptionHandler();
    }
  };

  const handleCloseToast = () => {
    setErrorMessage(undefined);
  };

  return (
    <>
      <Formik
        initialValues={{
          motivo: '',
        }}
        validationSchema={Yup.object().shape({
          motivo: Yup.string()
            .required('Motivo requerido'),
        })}
        onSubmit={() => {
        }}
      >
        <Box mt={10} mb={10}>
          <Grid container>
            <Grid item xs={1} md={4} />
            <Grid item xs={10} md={4}>
              <div style={{ border: '1px solid #b1a7a6' }}>
                <form onSubmit={formActionHandler}>
                  <Box mt={4}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Motivo"
                      name="motivo"
                      value={motivo}
                      onChange={changeMotiveHandler}
                      multiline
                      required
                      rows={4}
                    />
                  </Box>
                  {
                      rol.includes(i18n.rolAdmin) // me fijo si el rol si es admin
                        ? (
                          <Box m={4}>
                            <Button
                              variant="contained"
                              type="submit"
                              onClick={() => setSubmitType(i18n.commerceApproved)}
                              sx={{ mr: 2 }}
                              color="success"
                            >
                              {i18n.detailsAprove}

                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              onClick={() => setSubmitType(i18n.commerceRejected)}
                              color="error"
                            >
                              {i18n.detailsReject}

                            </Button>
                            <br />
                            <Collapse in={openAlert}>
                              <Alert
                                sx={{ mt: 2 }}
                                severity="success"
                                action={
                                (
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpenAlert(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                )
                              }
                              >
                                {i18n.detailsSendEmail}
                              </Alert>
                            </Collapse>
                          </Box>
                        )
                        : ( // sino es admin, me fijo si esta en observado para que el comercial pueda pedir excepcion
                          <Box m={4}>
                            <Button
                              variant="contained"
                              pb={3}
                              type="submit"
                              disabled={isLoading}
                              onClick={() => setSubmitType(i18n.detailsException)}
                            >
                              {i18n.detailsButtonException}
                            </Button>
                            {charactersException && (
                              <Alert severity="error" style={{ margin: '15px' }}>
                                {i18n.detailsMinimunCharactersException}
                              </Alert>
                            )}
                          </Box>
                        )
                    }
                </form>
              </div>
            </Grid>
            <Grid item xs={1} md={4} />
          </Grid>
        </Box>
      </Formik>
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={handleCloseToast}
        />
      )}
    </>
  );
};

changeStateBox.propTypes = {
  commerceId: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default changeStateBox;
