import { useHistory } from 'react-router-dom';
import { route } from '../constants/routes';
import { useUserData } from '../contexts/userDataContext';
import { getCurrentUserData, saveCurrentUserData } from '../utils/userStorage';
import { instance } from './axiosInstance';

const AuthInterceptor = () => {
  const history = useHistory();

  const { setUserData } = useUserData();

  const getLocalAccessToken = () => {
    const user = getCurrentUserData();
    return user?.accessToken;
  };

  instance.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken();
      if (token) {
        const configAuth = config;
        configAuth.headers.Authorization = `Bearer ${token}`;
        return configAuth;
      }
      return config;
    },
    (error) => { throw error; },
  );

  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response && err.response.status === 401) {
        setUserData({});
        saveCurrentUserData({});
        history.push({
          pathname: route.signIn,
          state: {
            redirectRoute: err.response.config.url,
          },
        });
      }
      throw err;
    },
  );

  return <></>;
};

export default AuthInterceptor;
