import { Grid } from '@material-ui/core';
import '../../assets/css/loadingstyle.css';
import i18n from './i18n';

const Loading = () => (
  <>
    <Grid container style={{ height: '50vh' }} alignContent="center" justifyContent="center">
      <Grid item xs={12}>
        <svg className="svg-cc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.5 91.7" style={{ enableBackground: 'new 0 0 90.5 91.7' }} xmlSpace="preserve">
          <path
            className="square-cc"
            d={i18n.loadingSVG1}
            style={{
              fill: 'none', stroke: '#ededed', strokeWidth: '10', strokeMiterlimit: '10',
            }}
          />
          <path
            className="square-cc"
            d={i18n.loadingSVG2}
            style={{
              fill: '#e62566', stroke: '#FF6EA6', strokeWidth: '2', strokeMiterlimit: '10',
            }}
          />
          <path
            d={i18n.loadingSVG3}
            style={{ fill: '#fff' }}
          />
        </svg>
      </Grid>
    </Grid>
  </>
);
export default Loading;
