import { useEffect, useState } from 'react';
import {
  Box, Button, Typography, Grid, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery, Dialog,
  DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core';
import {
  createTheme, responsiveFontSizes, ThemeProvider,
} from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useParams, useHistory, Link } from 'react-router-dom';
import i18n from '../components/common/i18n';
import imgVector from '../assets/images/listBranches/vector.svg';
import imgBranch from '../assets/images/listBranches/chicaTuercas.png';
import '../assets/css/listBranchesStyle.css';
import Loading from '../components/common/Loading';
import { getBasicDataCommerce, getBranchRequestByHeadOfficeTaxId, deleteBranch } from '../api/services';
import { formatDate } from '../utils/functionsUtil';
import { branchStatusMapper } from '../utils/statusMapper';

const listBranches = () => {
  const history = useHistory();
  const { idCommerce } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [branchList, setbranchList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogBranchName, setDialogBranchName] = useState('');
  const [dialogBranchId, setDialogBranchId] = useState('');

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const matchesMobile = useMediaQuery('(max-width: 900px)');

  const init = async () => {
    setIsLoading(true);
    try {
      const { data } = await getBasicDataCommerce(idCommerce);
      const res = await getBranchRequestByHeadOfficeTaxId(data.taxId);
      setbranchList(res.data.result);
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickOpenDialog = (branch) => {
    setDialogBranchName(branch.fantasyName);
    setDialogBranchId(branch.id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickDeleteBranch = async () => {
    try {
      await deleteBranch(dialogBranchId);
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setOpenDialog(false);
      init();
    }
  };

  const handleEditBranch = (branch) => {
    history.push(`/carga-sucursal/${idCommerce}/sucursal/${branch.id}`);
  };

  const isNotEditable = (branch) => branch.status === 'FINALIZED';

  useEffect(() => init(), []);

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && (
        <ThemeProvider theme={theme}>
          {
            matchesMobile
              ? (
                <Box className="boxListBranches gridFlex">
                  <Typography variant="h1" style={{ fontSize: '50px' }}>
                    {i18n.listBranchesManage}
                  </Typography>
                </Box>
              ) : (
                <Box className="boxListBranches gridFlex" style={{ backgroundImage: `url(${imgVector})` }}>
                  <Grid container>
                    <Grid xs={1} />
                    <Grid item xs={3} className="gridFlex">
                      <Box mt={7}>
                        <Typography variant="h1" style={{ fontSize: '50px' }} className="textLeft">
                          {i18n.listBranchesManage}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={3} />
                    <Grid item xs={3}>
                      <img
                        src={imgBranch}
                        className="imgBranch"
                        alt="Chica tuercas"
                      />
                    </Grid>
                    <Grid xs={2} />
                  </Grid>
                </Box>
              )
          }
          <Box mt={8}>
            <Grid container>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Typography variant="h5" className="textLeft">
                  {i18n.listBranchesText}
                </Typography>
                <Box mt={4}>
                  <Typography variant="h5" className="textLeft ">
                    {i18n.listBranchesStreet}
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="h5" className="textLeft">
                      {i18n.listBranchesContact}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="h5" className="textLeft">
                      {i18n.listBranchesManagerData}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="h5" className="textLeft">
                      {i18n.listBranchesVendorsData}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={5} fullWidth>
                  <Link
                    to={{ pathname: `/carga-sucursal/${idCommerce}/sucursal` }}
                  >
                    <Button variant="contained" fullWidth>
                      {i18n.listBranchesNew}
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </Box>
          <Box mt={5}>
            <Typography variant="h2">{i18n.listBranchesDetails}</Typography>
          </Box>
          <Box mt={5} mb={15}>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={10} className="tblList">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h4">
                          {i18n.listBranchesBranch}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">
                          {i18n.listBranchesState}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">
                          {i18n.listBranchesCreateDate}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">
                          {i18n.listBranchesLastModified}
                        </Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {branchList.map((branch) => (
                      <TableRow key={branch.id}>
                        <TableCell>
                          <Typography variant="h6">
                            {branch.fantasyName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">
                            {branchStatusMapper(branch.status)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">
                            {formatDate(branch.createdDate)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">
                            {formatDate(branch.lastModifiedDate)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton disabled={isNotEditable(branch)} onClick={() => handleEditBranch(branch)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton disabled={isNotEditable(branch)} onClick={() => handleClickOpenDialog(branch)}>
                            <DeleteIcon />
                          </IconButton>
                          <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {i18n.listBranchesAskDelete}
                                {dialogBranchName}
                                {i18n.questionMark}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleCloseDialog} className="btnNoDelete">{i18n.listBranchesNo}</Button>
                              <Button
                                onClick={onClickDeleteBranch}
                                className="btnAgreeDelete"
                                autoFocus
                              >
                                {i18n.listBranchesYes}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default listBranches;
