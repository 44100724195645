import Alert from '@mui/material/Alert';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { updateCommerceData } from '../../api/services';
import i18n from '../../components/common/i18n';
import Loading from '../../components/common/Loading';
import LoadingButton from '../../components/common/LoadingButton';
import TextFieldWrapper from '../../components/common/TextFieldWrapper';
import { fillMessageWith, translateErrorOrDefault } from '../../utils/functionsUtil';

const CommerceBoxManagerData = ({
  managerDataSuccess, setManagerDataSuccess, managerNameField, managerLastNameField, managerDocumentNumberField,
  managerPhoneField, managerEmailField, closeForm,
}) => {
  const { idCommerce } = useParams();

  const [isEditable, setIsEditable] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataSavedError, setDataSavedError] = useState(undefined);

  const onClickSubmit = async (data) => {
    setDataSavedError(undefined);
    setIsSaving(true);
    try {
      const request = {
        commerceData: {
          commerceManager_name: data.name?.toUpperCase(),
          commerceManager_lastName: data.lastName?.toUpperCase(),
          commerceManager_documentNumber: data.documentNumber,
          commerceManager_phone: data.phone,
          commerceManager_email: data.email,
        },
      };

      await updateCommerceData(idCommerce, request);

      setIsEditable(false);
      setManagerDataSuccess(true);
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
    setIsSaving(false);
  };

  useEffect(() => {
    setIsEditable(!managerDataSuccess);
  }, []);

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          name: managerNameField,
          lastName: managerLastNameField,
          documentNumber: managerDocumentNumberField,
          phone: managerPhoneField,
          email: managerEmailField,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerceName)),
          lastName: Yup.string()
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerceLastName)),
          documentNumber: Yup.string()
            .matches(/^[0-9]+$/, i18n.onlyNumbersAllowed)
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_commerceManager_documentNumber))
            .min(7, 'El DNI debe poseer al menos 7 caracteres')
            .max(8, 'El DNI debe poseer como máximo 8 caracteres'),
          phone: Yup.string()
            .matches(/^[0-9]+$/, i18n.onlyNumbersAllowed)
            .max(12, fillMessageWith(i18n.maxLenght, '12'))
            .min(10, fillMessageWith(i18n.minLenght, '10'))
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_phone)),
          email: Yup.string()
            .email(i18n.invalidFormat)
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_email)),
        })}
        onSubmit={(data) => {
          if (isEditable) {
            onClickSubmit(data);
          } else {
            setIsEditable(true);
          }
        }}
      >
        {({ isValid }) => (
          <Form>
            {isSaving === true && (
              <Loading />
            )}
            {isSaving === false && (
            <>
              <TextFieldWrapper
                margin="normal"
                fullWidth
                required
                name="name"
                label={i18n.formCommerceName}
                autoComplete={i18n.formCommerceName}
                disabled={!isEditable}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
              <TextFieldWrapper
                margin="normal"
                fullWidth
                required
                name="lastName"
                label={i18n.formCommerceLastName}
                autoComplete={i18n.formCommerceLastName}
                disabled={!isEditable}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
              <TextFieldWrapper
                margin="normal"
                fullWidth
                required
                name="documentNumber"
                inputProps={{ maxLength: 8 }}
                label={i18n.formCommerce_documentNumber}
                autoComplete={i18n.formCommerce_documentNumber}
                disabled={!isEditable}
              />
              <TextFieldWrapper
                margin="normal"
                fullWidth
                required
                name="phone"
                label={i18n.formCommerce_cellphone}
                autoComplete={i18n.formCommerce_cellphone}
                inputProps={{ maxLength: 12 }}
                disabled={!isEditable}
                helperText={i18n.formCommerce_cellphoneHelper}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <TextFieldWrapper
                margin="normal"
                fullWidth
                required
                name="email"
                label={i18n.formCommerce_email}
                autoComplete={i18n.formCommerce_email}
                disabled={!isEditable}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                style={{
                  margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
                }}
                type="submit"
                disabled={isEditable && !(isValid)}
                loading={isSaving}
              >
                {isEditable ? i18n.formCommerceButtonSave : i18n.formCommerceButtonEdit}
              </LoadingButton>
                {dataSavedError && (
                <Alert severity="error">
                  {dataSavedError}
                </Alert>
                )}
            </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

CommerceBoxManagerData.propTypes = {
  managerDataSuccess: PropTypes.bool.isRequired,
  setManagerDataSuccess: PropTypes.func.isRequired,
  managerNameField: PropTypes.string,
  managerLastNameField: PropTypes.string,
  managerDocumentNumberField: PropTypes.string,
  managerPhoneField: PropTypes.string,
  managerEmailField: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
};
CommerceBoxManagerData.defaultProps = {
  managerNameField: '',
  managerLastNameField: '',
  managerDocumentNumberField: '',
  managerPhoneField: '',
  managerEmailField: '',
};

export default CommerceBoxManagerData;
