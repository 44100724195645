import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './common/TabPanel';
import TrackTraceTable from './common/TrackTraceTable';

const TrackTraceViewer = ({ trackTrace }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
        >
          {trackTrace.map((trace) => (
            <Tab label={trace.groupName} />
          ))}
        </Tabs>
      </Box>
      {trackTrace.map((trace, index) => (
        <TabPanel value={value} index={index}>
          <TrackTraceTable variables={trace.variables} />
        </TabPanel>
      ))}
    </Box>
  );
};

TrackTraceViewer.propTypes = {
  trackTrace: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TrackTraceViewer;
