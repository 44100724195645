import {
  Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@mui/material/Alert';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import { route } from '../../constants/routes';
import { getCities, createBranch, updateBranch } from '../../api/services';
import i18n from '../../components/common/i18n';
import Loading from '../../components/common/Loading';
import LoadingButton from '../../components/common/LoadingButton';
import TextFieldWrapper from '../../components/common/TextFieldWrapper';
import { fillMessageWith, translateErrorOrDefault } from '../../utils/functionsUtil';

const branchBoxMainData = ({
  mainDataSuccess, setMainDataSuccess, taxId, commerceFantasyName, states,
  provinceField, cityField, postalCodeField, addressNameField, addressNumberField,
  phoneField, emailField, closeForm,
}) => {
  const { idCommerce, idBranch } = useParams();
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataSavedError, setDataSavedError] = useState(undefined);

  const [cities, setCities] = useState([]);
  const [defaultProvince, setDefaultProvince] = useState('');
  const [defaultCity, setDefaultCity] = useState('');

  useEffect(async () => {
    if (provinceField && provinceField.length > 0) {
      for (let i = 0; i < states.length; i += 1) {
        if (states[i].id === provinceField) {
          setDefaultProvince(states[i]);
        }
      }
      if (cityField && cityField.length > 0) {
        try {
          const { data } = await getCities(provinceField);
          for (let i = 0; i < data.length; i += 1) {
            if (data[i].id === cityField) {
              setDefaultCity(data[i]);
            }
          }
        } catch (error) {
          console.error('There was an error!', error);
        }
      }
    }
  }, []);

  const citiesList = async (province) => {
    try {
      const { data } = await getCities(province);
      setCities(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const handleProvinceStateChange = async (e, state) => {
    setDefaultProvince(state);
    setDefaultCity('');
    citiesList(state?.id);
  };

  const createNewBranch = async (data) => {
    const branchFantasyName = `${commerceFantasyName} ${data.addressName}`;
    try {
      const request = {
        headOfficeTaxId: taxId,
        fantasyName: `${branchFantasyName.toUpperCase()} ${data.addressNumber}`,
        street: data.addressName?.toUpperCase(),
        number: data.addressNumber,
        province: data.province,
        location: data.city,
        zipCode: data.postalCode,
        phone: data.phone,
        mail: data.email,
      };
      await createBranch(request)
        .then((response) => {
          setIsEditable(false);
          setMainDataSuccess(true);
          closeForm();
          if (!idBranch) {
            history.push(route
              .branchForm
              .replace(':idCommerce', idCommerce).replace(':idBranch', response.data.id));
          }
        });
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
  };

  const updateExistingBranch = async (data) => {
    const branchFantasyName = `${commerceFantasyName} ${data.addressName}`;
    try {
      const request = {
        fantasyName: branchFantasyName,
        street: data.addressName?.toUpperCase(),
        number: data.addressNumber,
        province: data.province,
        location: data.city,
        zipCode: data.postalCode,
        phone: data.phone,
        mail: data.email,
      };
      await updateBranch(request, idBranch);
      setIsEditable(false);
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
  };

  const onClickSubmit = async (data) => {
    setDataSavedError(undefined);
    setIsSaving(true);
    if (!mainDataSuccess) {
      createNewBranch(data);
    } else {
      updateExistingBranch(data);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    setIsEditable(!mainDataSuccess);
  }, []);

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          province: provinceField,
          city: cityField,
          postalCode: postalCodeField,
          addressName: addressNameField,
          addressNumber: addressNumberField,
          phone: phoneField,
          email: emailField,
        }}
        validationSchema={Yup.object().shape({
          addressName: Yup.string()
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_addressName)),
          addressNumber: Yup.string()
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_addressNumber)),
          province: Yup.string().required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_province)),
          city: Yup.string().required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_city)),
          postalCode: Yup.string()
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_postalCode)),
          phone: Yup.string()
            .typeError(i18n.onlyNumbersAllowed)
            .max(12, fillMessageWith(i18n.maxLenght, '12'))
            .min(10, fillMessageWith(i18n.minLenght, '10'))
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_phone)),
          email: Yup.string()
            .email(i18n.invalidFormat)
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_email)),
        })}
        onSubmit={() => console.log()}
      >
        {({
          setFieldValue, isValid, validateForm, values,
        }) => (
          <Form>
            {isSaving === true && (
              <Loading />
            )}
            {isSaving === false && (
              <>
                <Box m={1} style={{ width: '100%', display: 'flex', marginLeft: '0' }}>
                  <TextFieldWrapper
                    margin="normal"
                    name="addressName"
                    label={i18n.formBranchAddressName}
                    autoComplete={i18n.formBranchAddressName}
                    disabled={!isEditable}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                  <Box m={1} />
                  <TextFieldWrapper
                    margin="normal"
                    inputProps={{ maxLength: 6 }}
                    fullWidth
                    name="addressNumber"
                    label={i18n.formCommerce_addressNumber}
                    autoComplete={i18n.formCommerce_addressNumber}
                    disabled={!isEditable}
                  />
                </Box>
                <Box m={1} style={{ width: '100%', display: 'flex', marginLeft: '0' }}>
                  <Autocomplete
                    onChange={(e, value) => {
                      handleProvinceStateChange(e, value);
                      setFieldValue('province', value?.id);
                      setFieldValue('city', undefined);
                    }}
                    value={defaultProvince}
                    options={states}
                    getOptionLabel={(state) => state?.descripcion || ''}
                    getOptionSelected={(option, value) => !value.id || option.id === value.id}
                    disabled={!isEditable}
                    fullWidth
                    style={{ marginTop: '16px' }}
                    renderInput={(params) => (
                      <TextFieldWrapper
                        {...params}
                        name="province"
                        label={i18n.formCommerce_province}
                        fullWidth
                      />
                    )}
                  />
                  <Box m={1} />
                  <Autocomplete
                    onChange={(e, value) => {
                      setDefaultCity(value);
                      setFieldValue('city', value?.id);
                    }}
                    value={defaultCity}
                    options={cities}
                    disabled={!isEditable}
                    fullWidth
                    style={{ marginTop: '16px' }}
                    getOptionLabel={(city) => city?.descripcion || ''}
                    getOptionSelected={(option, value) => !value.id || option.id === value.id}
                    renderInput={(params) => (
                      <TextFieldWrapper
                        {...params}
                        name="city"
                        label={i18n.formCommerce_city}
                      />
                    )}
                  />
                  <Box m={1} />
                  <TextFieldWrapper
                    margin="normal"
                    name="postalCode"
                    inputProps={{ maxLength: 8 }}
                    fullWidth
                    label={i18n.formCommerce_postalCode}
                    autoComplete={i18n.formCommerce_postalCode}
                    disabled={!isEditable}
                  />
                </Box>
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="phone"
                  inputProps={{ maxLength: 12 }}
                  label={i18n.formCommerce_phone}
                  autoComplete={i18n.formCommerce_phone}
                  disabled={!isEditable}
                  helperText={i18n.formCommerce_phoneHelper}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="email"
                  label={i18n.formCommerce_email}
                  autoComplete={i18n.formCommerce_email}
                  disabled={!isEditable}
                />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
                  }}
                  type="submit"
                  disabled={isEditable && !(isValid)}
                  loading={isSaving}
                  onPress={
                    isEditable
                      ? async () => {
                        await setFieldValue('province', defaultProvince?.id);
                        await setFieldValue('city', defaultCity?.id);
                        validateForm().then(() => onClickSubmit(values));
                      }
                      : () => { setIsEditable(true); }
                  }
                >
                  {isEditable ? i18n.formCommerceButtonSave : i18n.formCommerceButtonEdit}
                </LoadingButton>
                {dataSavedError && (
                  <Alert severity="error">
                    {dataSavedError}
                  </Alert>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

branchBoxMainData.propTypes = {
  mainDataSuccess: PropTypes.bool.isRequired,
  setMainDataSuccess: PropTypes.func.isRequired,
  taxId: PropTypes.string.isRequired,
  commerceFantasyName: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(Object).isRequired,
  provinceField: PropTypes.string,
  cityField: PropTypes.string,
  postalCodeField: PropTypes.string,
  addressNameField: PropTypes.string,
  addressNumberField: PropTypes.string,
  phoneField: PropTypes.string,
  emailField: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
};
branchBoxMainData.defaultProps = {
  taxId: '',
  commerceFantasyName: '',
  provinceField: '',
  cityField: '',
  postalCodeField: '',
  addressNameField: '',
  addressNumberField: '',
  phoneField: '',
  emailField: '',
};

export default branchBoxMainData;
