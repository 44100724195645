import * as yup from 'yup';
import i18n from '../components/common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

/* eslint "import/prefer-default-export": off */
export const noteBoxSchemaValidation = yup.object().shape({
  comment: yup.string().required(i18n.noteBoxSchemaValidationMessageRequired),
});

export const userSchemaValidation = yup.object().shape({
  name: yup.string()
    .required(fillMessageWith(i18n.valueRequired, i18n.formCommerceName)),
  lastName: yup.string()
    .required(fillMessageWith(i18n.valueRequired, i18n.formCommerceLastName)),
  documentNumber: yup.string()
    .matches(/^[0-9]+$/, i18n.onlyNumbersAllowed)
    .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_commerceManager_documentNumber))
    .min(7, i18n.documentNumberMin)
    .max(8, i18n.documentNumberMax),
  phone: yup.string()
    .matches(/^[0-9]+$/, i18n.onlyNumbersAllowed)
    .max(12, fillMessageWith(i18n.maxLenght, '12'))
    .min(10, fillMessageWith(i18n.minLenght, '10'))
    .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_phone)),
  email: yup.string()
    .email(i18n.invalidFormat)
    .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_email)),
});
