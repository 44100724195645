import { useEffect } from 'react';
import {
  Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUserData } from '../contexts/userDataContext';
import { useRedirectRoute } from '../contexts/redirectRouteContext';
import NavBar from '../components/NavBar';
import { route } from '../constants/routes';

const PrivateRoute = ({ children, ...rest }) => {
  const { userData } = useUserData();
  const { setRedirectRoute } = useRedirectRoute();

  useEffect(() => {
    if (rest.path !== undefined && rest.path !== '/signIn') {
      setRedirectRoute({ route: rest.location.pathname });
    }
  }, [rest.path]);

  return (
    <>
      <Route
        {...rest}
        render={() =>
          (
            userData?.sub
              ? (
                <>
                  <NavBar />
                  {children}
                </>
              )
              : (
                <Redirect
                  to={{
                    pathname: route.signIn,
                    // state: { redirectRoute: rest.path },
                  }}
                />
              )
          )}
      />
    </>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default PrivateRoute;
