import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import {
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { authenticate } from '../api/services';
import image from '../assets/images/fondoLogin.png';
import Copyright from '../components/common/Copyright';
import i18n from '../components/common/i18n';
import TextFieldWrapper from '../components/common/TextFieldWrapper';
import { route } from '../constants/routes';
import { useRedirectRoute } from '../contexts/redirectRouteContext';
import { useUserData } from '../contexts/userDataContext';
import getDecodedToken from '../utils/decodeJwt';
import { saveCurrentUserData } from '../utils/userStorage';

const theme = createTheme();

const SignIn = () => {
  const [loginAlert, setLoginAlert] = useState(false);

  const initialValues = {
    username: '',
    password: '',
  };

  const { setUserData } = useUserData();
  const { redirectRoute } = useRedirectRoute();

  const history = useHistory();
  const validationSchema = Yup.object({
    username: Yup.string().email(i18n.invalidFormat).required(i18n.newCommerceRequired),
    password: Yup.string().required(i18n.newCommerceRequired),
  });

  const onSubmit = async (values) => {
    try {
      const { data } = await authenticate(values.username, values.password);
      const payload = await getDecodedToken(data.accessToken);
      payload.accessToken = data.accessToken;
      setUserData(payload);
      saveCurrentUserData(payload);
      if (data.accessToken) {
        setLoginAlert(false);
        if (redirectRoute && redirectRoute.route) {
          history.push(redirectRoute.route);
        } else {
          history.push(route.newCommerce);
        }
      } else {
        console.log('error de login');
      }
    } catch (error) {
      console.error('There was an error!', error);
      setLoginAlert(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="100%">
            <Box
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100%',
                backgroundColor: '#ffb5ab',
                backgroundPosition: 'center',
              }}
            >
              <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                  sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingRight: 35,
                    paddingLeft: 35,
                    paddingTop: 50,
                    height: '100vh',
                    float: 'left',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: '#FFDCE9' }} />
                  <Typography component="h1" variant="h5">
                    {i18n.newCommerceSignIn}
                  </Typography>
                  <Box noValidate sx={{ mt: 1 }}>
                    <TextFieldWrapper
                      margin="normal"
                      required
                      fullWidth
                      id={i18n.newCommerceUsername}
                      label={i18n.newCommerceMail}
                      name={i18n.newCommerceUsername}
                      autoComplete={i18n.newCommerceUsername}
                      autoFocus
                    />
                    <TextFieldWrapper
                      margin="normal"
                      required
                      fullWidth
                      name={i18n.newCommercePassword}
                      label={i18n.newCommercePasswordSpanish}
                      type={i18n.newCommercePassword}
                      id={i18n.newCommercePassword}
                      autoComplete="current-password"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Ingresar
                    </Button>
                    {
                    loginAlert ? (
                      <Box>
                        <Typography>
                          {i18n.newCommerceIncorrectPassword}
                        </Typography>
                      </Box>
                    ) : null
                    }
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                  </Box>
                </Box>
              </Container>
            </Box>
          </Container>
        </ThemeProvider>
      </Form>
    </Formik>
  );
};

export default SignIn;
