import { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import '../index.css';
import i18n from './common/i18n';
import menuOptions from '../constants/navBarRoutes';
import { saveCurrentUserData } from '../utils/userStorage';
import { useUserData } from '../contexts/userDataContext';

const NavBarMobile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setUserData } = useUserData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOutHandler = () => {
    setUserData({});
    saveCurrentUserData({});
  };

  /*
    const menuOptions = [
      { label: 'Inicio', path: '' },
      { label: 'Nuevo Comercio', path: '' },
      { label: 'Comercios', path: '' },
    ];
  */

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon style={{ color: 'black' }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuOptions.map((menuItem) => (
          <MenuItem
            key={menuItem.label}
            className="login-menu-mobile"
          >
            <a href={menuItem.path}>{menuItem.label}</a>
          </MenuItem>
        ))}
        <MenuItem
          key={i18n.menuOptionExit}
          className="login-menu-mobile"
          onClick={signOutHandler}
        >
          <a href="#/">{i18n.menuOptionExit}</a>
        </MenuItem>

      </Menu>
    </div>
  );
};

export default NavBarMobile;
