import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = () =>
  (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" target="_blank" href="https://www.credicuotas.com.ar">
        Credicuotas
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );

export default Copyright;
