import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

const SelectMenuItems = ({
  title, data, name, onChange, value,
}) =>
  /* const {
        state,
        data,
        setState,
      } = props; // ver si hace falta escribir props en la declaracion del componente
  */
  (
    <FormControl fullWidth>
      <InputLabel id="select-label">{title}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        name={name}
        value={value}
        label={title}
        onChange={onChange}
      >
        {
          data.map((e) =>
            <MenuItem value={e.id}>{e.value}</MenuItem>)
        }
      </Select>
    </FormControl>
  );

SelectMenuItems.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default SelectMenuItems;
