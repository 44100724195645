import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from './common/TextInput';
import { noteBoxSchemaValidation } from '../forms/schemaValidations';
import LoadingButton from './common/LoadingButton';
import i18n from './common/i18n';

const NoteBox = ({ loading, onSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(noteBoxSchemaValidation),
  });

  const loadingButtonStyle = {
    width: '100%',
    margin: '0px',
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className="note-box">
            <Grid item xs={12} md={8}>
              <TextInput
                fullWidth
                id="comment"
                name="comment"
                label={i18n.noteBoxLabel}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LoadingButton
                variant="contained"
                color="primary"
                style={loadingButtonStyle}
                type="submit"
                loading={loading}
              >
                {i18n.noteBoxCTA}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

NoteBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NoteBox;
