export const orderedDataList = [
  'commerceManager_documentNumber',
  'commerceManager_name',
  'commerceManager_lastName',
  'commerceManager_email',
  'phone',
  'commerceManager_phone',
  'email',
  'cbu',
  'addressName',
  'addressNumber',
  'floor',
  'apartment',
  'commerceProvince_description',
  'commerceCity_description',
  'postalCode',
];

export default orderedDataList;
