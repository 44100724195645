import { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const redirectRouteContext = createContext(null);

export const RedirectRouteProvider = ({ data, children }) => {
  const [redirectRoute, setRedirectRoute] = useState(data);

  return (
    <redirectRouteContext.Provider value={{ redirectRoute, setRedirectRoute }}>
      {children}
    </redirectRouteContext.Provider>
  );
};

RedirectRouteProvider.propTypes = {
  data: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

RedirectRouteProvider.defaultProps = {
  data: null,
};

export const useRedirectRoute = () => useContext(redirectRouteContext);
