import { Box, makeStyles } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { createCommerceAfilliationRequest, resolveSappCustomer } from '../api/services';
import i18n from '../components/common/i18n';
import LoadingButton from '../components/common/LoadingButton';
import TextFieldWrapper from '../components/common/TextFieldWrapper';
import { useUserData } from '../contexts/userDataContext';
import { fillMessageWith } from '../utils/functionsUtil';
import { statusMapper } from '../utils/statusMapper';

const useStyles = makeStyles(() => ({
  textField: {
    width: '10%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  input: {
    color: 'white',
  },
  boxAprobado: {
    border: '1',
    color: 'white',
    fontFamily: 'Poppins',
    height: '80%',
    width: '35%',
    borderRadius: '25px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxDetalle: {
    border: '1',
    color: 'white',
    fontFamily: 'Poppins',
    height: '10%',
    width: '100%',
    borderRadius: '25px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const commerceValidation = () => {
  const { userData } = useUserData();
  const classes = useStyles();
  const [calificacion, setCalificacion] = useState(null);
  const [commerceId, setCommerceId] = useState(null);
  const [razonSocialField, setRazonSocialField] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [errorBox, setErrorBox] = useState();

  const completeRazonSocialHandler = async (event) => {
    setErrorBox(null);
    const targetValue = event.target.value.replaceAll('-', '');
    try {
      const { data } = await resolveSappCustomer(targetValue);
      setRazonSocialField(data.razonSocial);
    } catch (error) {
      console.error('There was an error!', error);
      setRazonSocialField('');
      setErrorBox(fillMessageWith(i18n.newCommerceWarningTextCUIT, targetValue));
    }
  };

  const getContactList = (data) => {
    const contactList = [];
    contactList.push({
      type: 'EMAIL',
      value: data.email,
      primary: true,
    });
    contactList.push({
      type: 'INSTAGRAM',
      value: data.instagram,
      primary: false,
    });
    contactList.push({
      type: 'WEBSITE',
      value: data.website,
      primary: false,
    });
    return contactList;
  };

  const calificationCommerceHandler = async (data) => {
    setLoading(true);
    try {
      const response = await createCommerceAfilliationRequest(
        data.cuit.replaceAll('-', ''),
        razonSocialField,
        data.fantasia?.toUpperCase(),
        getContactList(data),
        data.observaciones?.toUpperCase(),
        userData.sub,
      );
      setCalificacion(response.data.status);
      setCommerceId(response.data.id);
      setShowButton(false);
    } catch (error) {
      if (error.response.data.code === 'COMMERCE_AFFILIATION_REQUEST_CUIT_ALREADY_EXIST') {
        setErrorBox(i18n.newCommerceAlreadyExist + statusMapper(error.response.data.message));
      } else {
        setErrorBox(i18n.newCommerceWarningText);
        setShowButton(false);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={4} md={3} sm={1} />
        <Grid item lg={4} md={6} sm={10}>
          <h1 style={{ marginTop: '3%' }}>{i18n.newCommerceQualify}</h1>

          <Box mx={3}>
            <Formik
              initialValues={{
                cuit: '',
                razonSocial: '',
                fantasia: '',
                email: '',
                instagram: '',
                website: '',
                observaciones: '',
              }}
              validationSchema={Yup.object().shape({
                cuit: Yup.string()
                  .required(fillMessageWith(i18n.valueRequired, i18n.newCommerceCuit)),
                fantasia: Yup.string()
                  .required(fillMessageWith(i18n.valueRequired, i18n.newCommerceFantasyName)),
                email: Yup.string()
                  .email(i18n.invalidFormat),
                observaciones: Yup.string(),
              })}
              onSubmit={(data) => {
                calificationCommerceHandler(data);
              }}
            >
              <Form>
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  id="cuit"
                  name="cuit"
                  label={i18n.newCommerceCuit}
                  autoComplete={i18n.newCommerceCuit}
                  autoFocus
                  onBlur={completeRazonSocialHandler}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  id="razonSocial"
                  name="razonSocial"
                  label={i18n.newCommerceName}
                  autoComplete={i18n.newCommerceName}
                  value={razonSocialField}
                  disabled // por default es true
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  id="fantasia"
                  name="fantasia"
                  label={i18n.newCommerceFantasyName}
                  autoComplete={i18n.newCommerceFantasyName}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  label={i18n.newCommerceEmail}
                  autoComplete={i18n.newCommerceEmail}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  id="instagram"
                  name="instagram"
                  label={i18n.newCommerceInstagram}
                  autoComplete={i18n.newCommerceInstagram}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  id="website"
                  name="website"
                  label={i18n.newCommerceWebsite}
                  autoComplete={i18n.newCommerceWebsite}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  id="observaciones"
                  name="observaciones"
                  label={i18n.newCommerceObservations}
                  autoComplete={i18n.newCommerceObservations}
                  multiline
                  maxRows={5}
                  helperText={i18n.newCommerceObservationsHelper}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                {showButton && (
                  <Box mx={8} sx={{ margin: '0!important' }}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      style={{ width: '100%' }}
                      type="submit"
                      loading={isLoading}
                    >
                      {i18n.newCommerceCalificationButton}
                    </LoadingButton>
                  </Box>
                )}
              </Form>
            </Formik>
          </Box>
          { calificacion && (
            <Box mx={7} sx={{ marginLeft: '5%', marginRight: '5%', marginTop: '2%' }}>
              {
                    calificacion === i18n.commercePreApproved ? (
                      <Stack mx={8} sx={{ margin: '0!important', width: '100%' }}>
                        <Alert severity="success">
                          {i18n.newCommerceSuccessText}
                        </Alert>
                      </Stack>

                    ) : (
                      <Stack mx={8} sx={{ margin: '0!important', width: '100%' }}>
                        <Alert severity="error">
                          {i18n.newCommerceRejectedText}
                        </Alert>
                      </Stack>
                    )
                  }
              <Box
                className={classes.boxDetalle}
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: '3%' }}
              >
                <Link
                  to={{ pathname: `/detalles-comercio/${commerceId}`, state: { idCommerce: commerceId } }}
                >
                  {i18n.newCommerceSeeDetail}
                </Link>
              </Box>
            </Box>
          )}
          { !calificacion && errorBox && (
            <Stack mx={7} sx={{ marginLeft: '5%', marginRight: '5%' }}>
              <Alert severity="warning">
                {errorBox}
              </Alert>
            </Stack>
          )}
        </Grid>
        <Grid item lg={4} md={3} sm={1} />
        <Box m={2} />
      </Grid>
    </>
  );
};

export default commerceValidation;
