import {
  Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@mui/material/Alert';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getCities, updateCommerceData } from '../../api/services';
import i18n from '../../components/common/i18n';
import Loading from '../../components/common/Loading';
import LoadingButton from '../../components/common/LoadingButton';
import TextFieldWrapper from '../../components/common/TextFieldWrapper';
import { fillMessageWith, translateErrorOrDefault } from '../../utils/functionsUtil';

const CommerceBoxMainData = ({
  mainDataSuccess, setMainDataSuccess, taxIdField, companyNameField, fantasyNameField, states,
  provinceField, cityField, postalCodeField, addressNameField, addressNumberField,
  floorField, apartmentField, phoneField, emailField, cbuField, closeForm,
}) => {
  const { idCommerce } = useParams();

  const [isEditable, setIsEditable] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataSavedError, setDataSavedError] = useState(undefined);

  const [cities, setCities] = useState([]);
  const [defaultProvince, setDefaultProvince] = useState('');
  const [defaultCity, setDefaultCity] = useState('');

  useEffect(async () => {
    if (provinceField && provinceField.length > 0) {
      for (let i = 0; i < states.length; i += 1) {
        if (states[i].id === provinceField) {
          setDefaultProvince(states[i]);
        }
      }
      if (cityField && cityField.length > 0) {
        try {
          const { data } = await getCities(provinceField);
          for (let i = 0; i < data.length; i += 1) {
            if (data[i].id === cityField) {
              setDefaultCity(data[i]);
            }
          }
        } catch (error) {
          console.error('There was an error!', error);
        }
      }
    }
  }, []);

  const citiesList = async (province) => {
    try {
      const { data } = await getCities(province);
      setCities(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const handleProvinceStateChange = async (e, state) => {
    setDefaultProvince(state);
    setDefaultCity('');
    citiesList(state?.id);
  };

  const onClickSubmit = async (data) => {
    if (data.province === defaultProvince?.id && data.city === defaultCity?.id) {
      setDataSavedError(undefined);
      setIsSaving(true);
      try {
        const request = {
          commerceData: {
            province: data.province,
            city: data.city,
            postalCode: data.postalCode,
            addressName: data.addressName?.toUpperCase(),
            addressNumber: data.addressNumber,
            floor: data.floor,
            apartment: data.apartment?.toUpperCase(),
            phone: data.phone,
            email: data.email,
            cbu: data.cbu,
          },
          fantasyName: data.fantasyName ? data.fantasyName.toUpperCase() : '',
        };

        await updateCommerceData(idCommerce, request);

        setIsEditable(false);
        setMainDataSuccess(true);
        closeForm();
      } catch (error) {
        console.error('There was an error!', error);
        setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
      }

      setIsSaving(false);
    }
  };

  useEffect(() => {
    setIsEditable(!mainDataSuccess);
  }, []);

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          taxId: taxIdField,
          companyName: companyNameField,
          fantasyName: fantasyNameField,
          province: provinceField,
          city: cityField,
          postalCode: postalCodeField,
          addressName: addressNameField,
          addressNumber: addressNumberField,
          floor: floorField,
          apartment: apartmentField,
          phone: phoneField,
          email: emailField,
          cbu: cbuField,
        }}
        validationSchema={Yup.object().shape({
          fantasyName: Yup.string().required(fillMessageWith(i18n.valueRequired, i18n.formCommerceFantasyName)),
          province: Yup.string().required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_province)),
          city: Yup.string().required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_city)),
          phone: Yup.string()
            .typeError(i18n.onlyNumbersAllowed)
            .max(12, fillMessageWith(i18n.maxLenght, '12'))
            .min(10, fillMessageWith(i18n.minLenght, '10'))
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_phone)),
          email: Yup.string()
            .email(i18n.invalidFormat)
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_email)),
          cbu: Yup.string()
            .matches(/^[0-9]+$/, i18n.onlyNumbersAllowed)
            .required(fillMessageWith(i18n.valueRequired, i18n.formCommerce_cbu))
            .min(22, i18n.formCommerceCbuMinimumHelper)
            .max(22, i18n.formCommerceCbuMaximumHelper),

        })}
        onSubmit={() => console.log()}
      >
        {({
          setFieldValue, isValid, validateForm, values,
        }) => (
          <Form>
            {isSaving === true && (
              <Loading />
            )}
            {isSaving === false && (
              <>
                <TextFieldWrapper
                  margin="normal"
                  required
                  fullWidth
                  name="taxId"
                  label={i18n.formCommerceTaxId}
                  autoComplete={i18n.formCommerceTaxId}
                  disabled
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="companyName"
                  label={i18n.formCommerceCompanyName}
                  autoComplete={i18n.formCommerceCompanyName}
                  disabled
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="fantasyName"
                  label={i18n.formCommerceFantasyName}
                  autoComplete={i18n.formCommerceFantasyName}
                  disabled={!isEditable}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
                <Box m={1} style={{ width: '100%', display: 'flex', marginLeft: '0' }}>
                  {/* TODO: autocompletar con lo que viene desde el backend */}
                  <Autocomplete
                    onChange={(e, value) => {
                      handleProvinceStateChange(e, value);
                      setFieldValue('province', value?.id);
                      setFieldValue('city', undefined);
                    }}
                    value={defaultProvince}
                    options={states}
                    getOptionLabel={(state) => state?.descripcion || ''}
                    getOptionSelected={(option, value) => !value.id || option.id === value.id}
                    disabled={!isEditable}
                    fullWidth
                    renderInput={(params) => (
                      <TextFieldWrapper
                        {...params}
                        name="province"
                        label={i18n.formCommerce_province}
                        fullWidth
                      />
                    )}
                  />
                  <Box m={1} />
                  {/* TODO: autocompletar con lo que viene desde el backend */}
                  <Autocomplete
                    onChange={(e, value) => {
                      setDefaultCity(value);
                      setFieldValue('city', value?.id);
                    }}
                    value={defaultCity}
                    options={cities}
                    disabled={!isEditable}
                    fullWidth
                    getOptionLabel={(city) => city?.descripcion || ''}
                    getOptionSelected={(option, value) => !value.id || option.id === value.id}
                    renderInput={(params) => (
                      <TextFieldWrapper
                        {...params}
                        name="city"
                        label={i18n.formCommerce_city}
                      />
                    )}
                  />
                </Box>
                <Box m={1} style={{ width: '100%', display: 'flex', marginLeft: '0' }}>
                  <TextFieldWrapper
                    margin="normal"
                    name="addressName"
                    label={i18n.formCommerce_addressName}
                    autoComplete={i18n.formCommerce_addressName}
                    disabled={!isEditable}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                  <Box m={1} />
                  <TextFieldWrapper
                    margin="normal"
                    inputProps={{ maxLength: 6 }}
                    fullWidth
                    name="addressNumber"
                    label={i18n.formCommerce_addressNumber}
                    autoComplete={i18n.formCommerce_addressNumber}
                    disabled={!isEditable}
                  />
                </Box>
                <Box m={1} style={{ width: '100%', display: 'flex', marginLeft: '0' }}>
                  <TextFieldWrapper
                    margin="normal"
                    fullWidth
                    name="floor"
                    label={i18n.formCommerce_floor}
                    autoComplete={i18n.formCommerce_floor}
                    disabled={!isEditable}
                  />
                  <Box m={1} />
                  <TextFieldWrapper
                    margin="normal"
                    fullWidth
                    name="apartment"
                    label={i18n.formCommerce_apartment}
                    autoComplete={i18n.formCommerce_apartment}
                    disabled={!isEditable}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                  <Box m={1} />
                  <TextFieldWrapper
                    margin="normal"
                    name="postalCode"
                    inputProps={{ maxLength: 8 }}
                    fullWidth
                    label={i18n.formCommerce_postalCode}
                    autoComplete={i18n.formCommerce_postalCode}
                    disabled={!isEditable}
                  />
                </Box>
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="phone"
                  inputProps={{ maxLength: 12 }}
                  label={i18n.formCommerce_phone}
                  autoComplete={i18n.formCommerce_phone}
                  disabled={!isEditable}
                  helperText={i18n.formCommerce_phoneHelper}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="email"
                  label={i18n.formCommerce_email}
                  autoComplete={i18n.formCommerce_email}
                  disabled={!isEditable}
                />
                <TextFieldWrapper
                  margin="normal"
                  fullWidth
                  name="cbu"
                  inputProps={{ maxLength: 22 }}
                  label={i18n.formCommerce_cbu}
                  autoComplete={i18n.formCommerce_cbu}
                  disabled={!isEditable}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
                  }}
                  type="submit"
                  disabled={isEditable && !(isValid)}
                  loading={isSaving}
                  onPress={
                    isEditable
                      ? async () => {
                        await setFieldValue('province', defaultProvince?.id);
                        await setFieldValue('city', defaultCity?.id);
                        validateForm().then(() => onClickSubmit(values));
                      }
                      : () => { setIsEditable(true); }
                  }
                >
                  {isEditable ? i18n.formCommerceButtonSave : i18n.formCommerceButtonEdit}
                </LoadingButton>
                {dataSavedError && (
                  <Alert severity="error">
                    {dataSavedError}
                  </Alert>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

CommerceBoxMainData.propTypes = {
  mainDataSuccess: PropTypes.bool.isRequired,
  setMainDataSuccess: PropTypes.func.isRequired,
  taxIdField: PropTypes.string.isRequired,
  companyNameField: PropTypes.string.isRequired,
  fantasyNameField: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(Object).isRequired,
  provinceField: PropTypes.string,
  cityField: PropTypes.string,
  postalCodeField: PropTypes.string,
  addressNameField: PropTypes.string,
  addressNumberField: PropTypes.string,
  floorField: PropTypes.string,
  apartmentField: PropTypes.string,
  phoneField: PropTypes.string,
  emailField: PropTypes.string,
  cbuField: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
};
CommerceBoxMainData.defaultProps = {
  provinceField: '',
  cityField: '',
  postalCodeField: '',
  addressNameField: '',
  addressNumberField: '',
  floorField: '',
  apartmentField: '',
  phoneField: '',
  emailField: '',
  cbuField: '',
};

export default CommerceBoxMainData;
