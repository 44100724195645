import { Box, Grid, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import FilterBranches from '../components/common/filterBranches';
import i18n from '../components/common/i18n';
import columns from '../constants/columns/columnsListBranches';
import { branchStatusMapper } from '../utils/statusMapper';

const ListBranchesFilter = () => {
  const [results, setResults] = useState([]);

  const options = {
    textLabels: {
      body: {
        noMatch: 'No se encuentran resultados para la búsqueda realizada',
      },
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
  };

  const mapper = (data) => {
    const newArray = data;
    for (let i = 0; i < data.length; i += 1) {
      newArray[i].status = branchStatusMapper(data[i].status);
    }
  };

  const showResults = (branchResults) => {
    mapper(branchResults.result);
    setResults(branchResults.result);
  };

  if (columns.length === 5 && results !== []) {
    columns.push(
      {
        name: 'id',
        label: 'Detalle',
        options: {
          customBodyRender: (value) => (
            <Link
              to={{ pathname: `/detalles-sucursal/${value}`, state: { idBranch: value } }}
            >
              {i18n.listCommerceSeeDetail}
            </Link>
          ),
          filter: false,
          sort: false,
        },
      },
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box my={10}>
            <Typography variant="h2">{i18n.listBranchesTitle}</Typography>
            <Box mb={5} />
            <FilterBranches showResults={showResults} />
            <MUIDataTable
              title={i18n.listBranchesFilter}
              data={results}
              columns={columns}
              options={options}
            />
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default ListBranchesFilter;
