import { Box, Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { pendingApproval } from '../api/services';
import i18n from '../components/common/i18n';
import Loading from '../components/common/Loading';
import Columns from '../constants/columns/columnsAproveCommerce';
import { statusMapper } from '../utils/statusMapper';

const options = {
  textLabels: {
    body: {
      noMatch: 'No se encuentran resultados',
    },
  },
  selectableRows: 'none',
  responsive: 'standard',
  rowsPerPage: 10,
};

const AproveCommerce = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [commerceData, setCommerceData] = useState([]);

  const mapper = (data) => {
    const newArray = data;
    for (let i = 0; i < data.length; i += 1) {
      newArray[i].status = statusMapper(data[i].status);
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    try {
      const { data } = await pendingApproval();
      mapper(data);
      setCommerceData(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  }, []);

  if (Columns.length === 6 && commerceData !== []) {
    Columns.push(
      {
        name: 'id',
        label: 'Detalle',
        options: {
          customBodyRender: (value) => (
            <Link
              to={{ pathname: `/detalles-comercio/${value}`, state: { idCommerce: value } }}
            >
              {i18n.approveCommerceSeeDetail}
            </Link>
          ),
          filter: false,
          sort: false,
        },

      },
    );
  }

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && (
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Box my={10}>
              <MUIDataTable
                title={i18n.aproveCommerceGridTitle}
                data={commerceData}
                columns={Columns}
                options={options}
              />
            </Box>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      )}
    </>
  );
};

export default AproveCommerce;
