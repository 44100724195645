import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import i18n from '../../components/common/i18n';
import { createUser, updateUser, deleteUser } from '../../api/services';
import TextFieldWrapper from '../../components/common/TextFieldWrapper';
import Loading from '../../components/common/Loading';
import LoadingButton from '../../components/common/LoadingButton';
import { translateErrorOrDefault } from '../../utils/functionsUtil';
import { userSchemaValidation } from '../../forms/schemaValidations';

const sellerData = ({
  sellerDataSuccess, setSellerDataSuccess, sellerNameField, sellerLastNameField, sellerDocumentNumberField,
  sellerPhoneField, sellerEmailField, closeForm, init,
}) => {
  const { idBranch } = useParams();
  const [isEditable, setIsEditable] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataSavedError, setDataSavedError] = useState(undefined);
  const [dataDeletedError, setDataDeletedError] = useState(undefined);
  const [documentNumber, setDocumentNumber] = useState();

  const createNewSeller = async (data) => {
    try {
      const request = {
        name: data.name?.toUpperCase(),
        lastName: data.lastName?.toUpperCase(),
        document: data.documentNumber,
        phone: data.phone,
        mail: data.email,
        role: 'SELLER',
      };
      await createUser(request, idBranch);
      setDocumentNumber(data.documentNumber);
      setIsEditable(false);
      setSellerDataSuccess(true);
      init();
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
  };

  const updateSeller = async (data) => {
    try {
      const request = {
        name: data.name?.toUpperCase(),
        lastName: data.lastName?.toUpperCase(),
        phone: data.phone,
        mail: data.email,
        role: 'SELLER',
      };
      await updateUser(request, idBranch, data.documentNumber);
      setIsEditable(false);
      // setSellerDataSuccess(true);
      init();
      closeForm();
    } catch (error) {
      console.error('There was an error!', error);
      setDataSavedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorSavingData));
    }
  };

  const deleteSeller = async () => {
    try {
      await deleteUser(idBranch, documentNumber);
      setIsEditable(false);
      // setSellerDataSuccess(false);
      setIsSaving(false);
      closeForm();
      init();
    } catch (error) {
      console.error('There was an error!', error);
      setDataDeletedError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorDeleteData));
    }
  };

  const onClickSubmit = async (data) => {
    setDataSavedError(undefined);
    setIsSaving(true);

    if (!sellerDataSuccess) {
      await createNewSeller(data);
    } else {
      await updateSeller(data);
    }

    setIsSaving(false);
  };

  useEffect(() => {
    setIsEditable(!sellerDataSuccess);
    setDocumentNumber(sellerDocumentNumberField);
  }, []);

  return (
    <Formik
      validateOnMount
      initialValues={{
        name: sellerNameField,
        lastName: sellerLastNameField,
        documentNumber: sellerDocumentNumberField,
        phone: sellerPhoneField,
        email: sellerEmailField,
      }}
      validationSchema={userSchemaValidation}
      onSubmit={(data) => {
        if (isEditable) {
          onClickSubmit(data);
        } else {
          setIsEditable(true);
        }
      }}
    >
      {({ isValid }) => (
        <Form>
          {isSaving === true && (
            <Loading />
          )}
          {isSaving === false && (
          <>
            <TextFieldWrapper
              margin="normal"
              fullWidth
              required
              name="name"
              label={i18n.formCommerceName}
              autoComplete={i18n.formCommerceName}
              disabled={!isEditable}
              inputProps={{ style: { textTransform: 'uppercase' } }}
            />
            <TextFieldWrapper
              margin="normal"
              fullWidth
              required
              name="lastName"
              label={i18n.formCommerceLastName}
              autoComplete={i18n.formCommerceLastName}
              disabled={!isEditable}
              inputProps={{ style: { textTransform: 'uppercase' } }}
            />
            <TextFieldWrapper
              margin="normal"
              fullWidth
              required
              name="documentNumber"
              inputProps={{ maxLength: 8 }}
              label={i18n.formCommerce_documentNumber}
              autoComplete={i18n.formCommerce_documentNumber}
              disabled={!isEditable || sellerDataSuccess}
            />
            <TextFieldWrapper
              margin="normal"
              fullWidth
              required
              name="phone"
              label={i18n.formCommerce_cellphone}
              autoComplete={i18n.formCommerce_cellphone}
              inputProps={{ maxLength: 12 }}
              disabled={!isEditable}
              helperText={i18n.formCommerce_cellphoneHelper}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextFieldWrapper
              margin="normal"
              fullWidth
              required
              name="email"
              label={i18n.formCommerce_email}
              autoComplete={i18n.formCommerce_email}
              disabled={!isEditable}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              style={{
                margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
              }}
              type="submit"
              disabled={isEditable && !(isValid)}
              loading={isSaving}
            >
              {isEditable ? i18n.formCommerceButtonSave : i18n.formCommerceButtonEdit}
            </LoadingButton>
              {dataSavedError && (
              <Alert severity="error">
                {dataSavedError}
              </Alert>
              )}
            <LoadingButton
              variant="contained"
              color="primary"
              style={{
                margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
              }}
              type="submit"
              disabled={!sellerDataSuccess}
              loading={isSaving}
              onPress={deleteSeller}
            >
              {i18n.listBranchesDelete}
            </LoadingButton>
            {dataDeletedError && (
              <Alert severity="error">
                {dataDeletedError}
              </Alert>
            )}
          </>
          )}
        </Form>
      )}
    </Formik>
  );
};

sellerData.propTypes = {
  defaultForm: PropTypes.bool.isRequired,
  sellerDataSuccess: PropTypes.bool.isRequired,
  setSellerDataSuccess: PropTypes.func.isRequired,
  sellerNameField: PropTypes.string,
  sellerLastNameField: PropTypes.string,
  sellerDocumentNumberField: PropTypes.string,
  sellerPhoneField: PropTypes.string,
  sellerEmailField: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
};
sellerData.defaultProps = {
  defaultForm: true,
  sellerNameField: '',
  sellerLastNameField: '',
  sellerDocumentNumberField: '',
  sellerPhoneField: '',
  sellerEmailField: '',
};

export default sellerData;
