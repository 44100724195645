import qs from 'qs';
import { dataUrlToBlob } from '../utils/dataUrlToBlob';
import { fetch } from './axiosInstance';

export const COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE = '/v1/commerce-affiliation-requests';
export const COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC = '/v1/commerce-affiliation-requests/p';
export const BRANCH_REQUESTS_URL = '/v1/branch-requests';

export const getBasicDataCommerce = (idCommerce) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC}/${idCommerce}`,
    method: 'get',
  });

export const getStates = () =>
  fetch({
    url: '/v1/locations/states',
    method: 'get',
  });

export const getCities = (id) =>
  fetch({
    url: `/v1/locations/states/${id}/cities`,
    method: 'get',
  });

export const updateCommerceData = (idCommerce, data) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC}/${idCommerce}`,
    method: 'put',
    data,
  });

export const updateContactData = (idCommerce, phone, email) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC}/${idCommerce}`,
    method: 'put',
    data: {
      contactData: [
        {
          type: 'CELLPHONE',
          value: phone,
          primary: true,
        },
        {
          type: 'EMAIL',
          value: email,
          primary: true,
        },
      ],
    },
  });

export const uploadFile = (idCommerce, type, file) => {
  const postData = new FormData();
  postData.append('docFile', file);
  return fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC}/${idCommerce}/documents/${type}`,
    method: 'post',
    data: postData,
  });
};

export const signDisclaimer = (signDataUrl, hash) => {
  const signData = dataUrlToBlob(signDataUrl);
  const formData = new FormData();
  formData.append('signData', signData);
  return fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC}/signDisclaimer/${hash}`,
    method: 'post',
    resumeSizeUrl: null,
    resumeChunkSize: null,
    data: formData,
  });
};

export const getStatus = () =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/status`,
    method: 'get',
  });

export const getSellers = () =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/users`,
    method: 'get',
  });

export const getFilteredCommerce = (filterValues) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}?${new URLSearchParams(filterValues)}`,
    method: 'get',
  });

export const updateCommerceRequestAfilliationStatus = (commerceId, submitType, motivo) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${commerceId}/status`,
    method: 'put',
    data: { status: submitType, comment: motivo },
  });

export const requestException = (commerceId, motivo) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${commerceId}/requestException`,
    method: 'post',
    data: { comment: motivo },
  });

export const pendingApproval = () =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/pendingApproval`,
    method: 'get',
  });

export const getCommerceRequestAfilliationList = (commerceId) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${commerceId}`,
    method: 'get',
  });

export const getNosisFile = (taxId) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/report/nosis/${taxId}`,
    method: 'get',
    responseType: 'blob',
  });

export const getPublicUrlImage = (commerceId, filename) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${commerceId}/documents?filename=${filename}`,
    method: 'get',
  });

export const resolveSappCustomer = (taxId) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/afip/identification/${taxId}`,
    method: 'get',
  });

export const createCommerceAfilliationRequest = (
  taxId, razonSocial, fantasyName, contactList, comment, user,
) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}`,
    method: 'post',
    data: {
      taxId,
      name: razonSocial,
      fantasyName,
      contactData: contactList,
      noteList: [
        {
          comment,
          user,
          date: Date.now(),
          type: 'USER_OBSERVATION',
        },
      ],
    },
  });

export const authenticate = (username, password) =>
  fetch({
    url: '/v1/signin/authenticate',
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    data: qs.stringify({ username, password }),
  });

export const downloadDisclaimer = () =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PUBLIC}/download/disclaimer`,
    method: 'get',
    responseType: 'blob',
  });

export const saveCommerceAfilliationInSapp = (idCommerce, userLogged) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${idCommerce}/saveCommerceAfilliationInSapp`,
    method: 'post',
    data: { userLogged },
  });

export const addNote = (idCommerce, comment) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${idCommerce}/notes`,
    method: 'post',
    data: { comment },
  });

export const getBranchRequestByHeadOfficeTaxId = (headOfficeTaxId) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}?headOfficeTaxId=${headOfficeTaxId}&pageSize=50`,
    method: 'get',
  });

export const getBranchRequestById = (branchId) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${branchId}`,
    method: 'get',
  });

export const createBranch = (data) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}`,
    method: 'post',
    data,
  });

export const updateBranch = (data, idBranch) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}`,
    method: 'put',
    data,
  });

export const createUser = (data, idBranch) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}/users`,
    method: 'post',
    data,
  });

export const updateUser = (data, idBranch, documentUser) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}/users/${documentUser}`,
    method: 'put',
    data,
  });

export const deleteUser = (idBranch, documentUser) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}/users/${documentUser}`,
    method: 'delete',
  });

export const getBranchStatusList = () =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/status`,
    method: 'get',
  });

export const getBranchList = (filterValues) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}?${new URLSearchParams(filterValues)}`,
    method: 'get',
  });

export const getBranchRequestAfilliationList = (branchId) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${branchId}`,
    method: 'get',
  });

export const updateBranchStatus = (idBranch, data) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}/status`,
    method: 'put',
    data,
  });

export const deleteBranch = (idBranch) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}`,
    method: 'delete',
  });

export const saveBranchInSapp = (idBranch, userLogged) =>
  fetch({
    url: `${BRANCH_REQUESTS_URL}/${idBranch}/saveBranchInSapp`,
    method: 'post',
    data: { userLogged },
  });

export const getProductList = () =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/products`,
    method: 'get',
  });

export const getSubproductList = (productId) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/products/${productId}/subproducts`,
    method: 'get',
  });

export const saveProductDetailList = (idCommerce, productDetailList) =>
  fetch({
    url: `${COMMERCE_AFFILIATION_REQUESTS_URL_PRIVATE}/${idCommerce}/products/details`,
    method: 'post',
    data: productDetailList,
  });
