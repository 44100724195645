import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import AuthInterceptor from '../api/authInterceptor';
import { route } from '../constants/routes';
import { RedirectRouteProvider } from '../contexts/redirectRouteContext';
import { UserDataProvider } from '../contexts/userDataContext';
import AproveCommerce from '../pages/aproveCommerce';
import CommerceDetails from '../pages/commerceDetails';
import CommerceForm from '../pages/commerceForm/commerceForm';
import ListCommerce from '../pages/listCommerce';
import NewCommerce from '../pages/newCommerce';
import SignIn from '../pages/signIn';
import WelcomeCommerce from '../pages/welcomeCommerce';
import WelcomeBranches from '../pages/welcomeBranches';
import ListBranches from '../pages/listBranches';
import BranchForm from '../pages/branchForm/branchForm';
import ListBranchesFilter from '../pages/listBranchesFilter';
import BranchDetails from '../pages/branchDetails';
import { getCurrentUserData } from '../utils/userStorage';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';

const AppRouter = () => {
  const userData = getCurrentUserData();

  const privateRoutesContainer = () => (
    <>
      <AuthInterceptor />
      <Switch>
        <PrivateRoute exact path={route.newCommerce}>
          <NewCommerce />
        </PrivateRoute>
        <PrivateRoute exact path={route.aproveCommerce}>
          <AproveCommerce />
        </PrivateRoute>
        <PrivateRoute exact path={route.listCommerce}>
          <ListCommerce />
        </PrivateRoute>
        <PrivateRoute path={route.commerceDetails}>
          <CommerceDetails />
        </PrivateRoute>
        <PrivateRoute path={route.listBranchesFilter}>
          <ListBranchesFilter />
        </PrivateRoute>
        <PrivateRoute path={route.branchDetails}>
          <BranchDetails />
        </PrivateRoute>
        <PrivateRoute from="*">
          <Redirect to={route.newCommerce} />
        </PrivateRoute>
      </Switch>
    </>
  );

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path={route.welcomeCommerce}>
            <WelcomeCommerce />
          </Route>
          <PublicRoute path={route.commerceForm}>
            <CommerceForm />
          </PublicRoute>
          <PublicRoute path={route.welcomeBranches}>
            <WelcomeBranches />
          </PublicRoute>
          <PublicRoute path={route.listBranches}>
            <ListBranches />
          </PublicRoute>
          <PublicRoute path={route.branchForm}>
            <BranchForm />
          </PublicRoute>
          <UserDataProvider data={userData}>
            <RedirectRouteProvider>
              <Route component={privateRoutesContainer} />
              <Route exact path={route.signIn} render={() => <SignIn />} />
              { /* <Route exact path={route.signIn}>
                <SignIn />
              </Route>  */}
            </RedirectRouteProvider>
          </UserDataProvider>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
