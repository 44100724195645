/* eslint-disable indent */
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import logoCredicuotas from '../logoCredicuotas.png';

const useStyles = makeStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0px 0px 10px #d6d6d6',
    position: 'relative',
    borderBottom: '1px solid #D6D6D6',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
});

const PublicRoute = ({ children, path, ...props }) => {
  const classes = useStyles();
    return (
      <Route
        {...props}
        render={() => (
          <>
            <Grid container className={classes.header} alignContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <img
                  src={logoCredicuotas}
                  className="logo-cc"
                  alt=""
                  width="200px"
                  style={{ float: 'left', marginLeft: '25px' }}
                />
              </Grid>
            </Grid>
            {children}
          </>
        )}
      />
    );
};

PublicRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};
PublicRoute.defaultProps = {
  children: undefined,
  path: undefined,
};
export default PublicRoute;
