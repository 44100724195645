import { Box, Button } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getBasicDataCommerce } from '../api/services';
import i18n from '../components/common/i18n';
import image from '../assets/images/ondaTitle.jpg';
import '../assets/css/welcomeCommerce.css';

const WelcomeCommerce = () => {
  const { idSolicitud } = useParams();
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(async () => {
    try {
      await getBasicDataCommerce(idSolicitud);
    } catch (error) {
      console.error('There was an error!', error);
      setErrorMessage(true);
    }
  }, []);

  return (
    <>
      { errorMessage ? (
        <Box sx={{
          width: '250px', marginTop: '5%', marginLeft: { xs: '5%', sm: '30%', md: '40%' },
        }}
        >
          <div />
          <Stack sx={{ margin: '0!important', width: '100%' }}>
            <Alert severity="error">
              {i18n.welcomeCommerceError}
            </Alert>
          </Stack>
        </Box>
      ) : (
        <>
          <Container
            maxWidth="sm"
            sx={{
              textAlign: 'left',
            }}
          >
            <Box sx={{
              color: 'white',
              fontSize: '0',
              backgroundImage: `url(${image})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '100%',
              height: '180px',
              marginTop: '2%',
            }}
            >
              <h1>
                {i18n.welcomeCommerceFirstP}
              </h1>
            </Box>
            <p>
              {i18n.welcomeCommerceSecondP}
            </p>
            <p>
              {i18n.welcomeCommerceThirdP}
            </p>
            <p className="pointAdhesion">
              {i18n.welcomeCommerceFirstRequisite}
            </p>
            <p className="pointAdhesion">
              {i18n.welcomeCommerceSecondRequisite}
            </p>
            <p className="pointAdhesion">
              {i18n.welcomeCommerceThirdRequisite}
            </p>
            <p>
              {i18n.welcomeCommerceFourP}
            </p>
            <Link
              to={{ pathname: `/formulario-comercio/${idSolicitud}` }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                className="WelcomeCommerceButton"
                variant="contained"
                color="primary"
              >
                {i18n.welcomeCommerceButton}
              </Button>
            </Link>
          </Container>
        </>
      )}
    </>
  );
};

export default WelcomeCommerce;
