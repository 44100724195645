import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import i18n from './i18n';

const TrackTraceTable = ({ variables }) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center"><b>{i18n.detailsDecisionEngineResponseName}</b></TableCell>
          <TableCell align="center"><b>{i18n.detailsDecisionEngineResponseValue}</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variables.map((variable) => (
          <TableRow
            key={variable.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row" align="center">
              {variable.id}
            </TableCell>
            <TableCell align="center">
              {variable.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

TrackTraceTable.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TrackTraceTable;
