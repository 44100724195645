import { Box, Grid, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import FilterCommerce from '../components/common/filterCommerce';
import i18n from '../components/common/i18n';
import Columns from '../constants/columns/columnsListCommerce';
import { statusMapper } from '../utils/statusMapper';

const ListCommerce = () => {
  const [results, setResults] = useState([]);

  const options = {
    textLabels: {
      body: {
        noMatch: 'No se encuentran resultados para la búsqueda realizada',
      },
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
  };

  const mapper = (data) => {
    const newArray = data;
    for (let i = 0; i < data.length; i += 1) {
      newArray[i].status = statusMapper(data[i].status);
    }
  };

  const showResults = (commerceResults) => {
    mapper(commerceResults.result);
    setResults(commerceResults.result);
  };

  if (Columns.length === 6 && results !== []) {
    Columns.push(
      {
        name: 'id',
        label: 'Detalle',
        options: {
          customBodyRender: (value) => (
            <Link
              to={{ pathname: `/detalles-comercio/${value}`, state: { idCommerce: value } }}
            >
              {i18n.listCommerceSeeDetail}
            </Link>
          ),
          filter: false,
          sort: false,
        },
      },
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box my={10}>
            <Typography variant="h2">{i18n.listCommerceTitle}</Typography>
            <Box mb={5} />
            <FilterCommerce showResults={showResults} />
            <MUIDataTable
              title={i18n.listCommerceGridTitle}
              data={results}
              columns={Columns}
              options={options}
            />
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default ListCommerce;
