import Alert from '@mui/material/Alert';
import { CircularProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { signDisclaimer, downloadDisclaimer } from '../../api/services';
import i18n from '../../components/common/i18n';
import SignatureBox from '../../components/common/SignatureBox';
import { translateErrorOrDefault } from '../../utils/functionsUtil';

const CommerceBoxSignDisclaimer = ({
  mainDataSuccess, managerDataSuccess, uploadDNIFrontSuccess, uploadDNIBackSuccess, uploadDocumentCUITSuccess,
  setSignDisclaimerSuccess, closeForm,
}) => {
  const { idCommerce } = useParams();

  const [isSaving, setIsSaving] = useState(false);
  const [signatureReady, setSignatureReady] = useState(false);
  const [signedSuccess, setSignedSuccess] = useState(false);
  const [signedError, setSignedError] = useState(false);
  const signatureRef = useRef();
  const [isDownloading, setIsDownloading] = useState(false);

  const onClickDownloadDisclaimer = async () => {
    try {
      setIsDownloading(true);
      const { data } = await downloadDisclaimer();
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const date = new Date();
      a.download = `${date.getFullYear()}_${date.getMonth() + 1}_afiliacionComercioContrato.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const onClickSubmitSignedCommerce = async () => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      setSignatureReady(true);

      if (mainDataSuccess && managerDataSuccess
          && uploadDNIFrontSuccess && uploadDNIBackSuccess && uploadDocumentCUITSuccess) {
        setIsSaving(true);

        try {
          await signDisclaimer((signatureRef.current.toDataURL()).toString(), idCommerce);

          setSignedError(false);
          setSignedSuccess(true);
          setSignDisclaimerSuccess(true);
          setSignatureReady(false);
          closeForm();
        } catch (error) {
          console.error('There was an error!', error);
          setSignedError(translateErrorOrDefault(error.response.data?.code, i18n.errorSignature));
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  return (
    <>
      <Formik>
        <Form>
          { signedSuccess ? (
            <Alert severity="success">
              {i18n.sucessSignature}
            </Alert>
          ) : (
            <>
              <div>
                <button
                  disabled={isDownloading}
                  className="button-like-link"
                  style={{ marginBottom: '10px' }}
                  type="button"
                  onClick={() => onClickDownloadDisclaimer()}
                >
                  {i18n.formCommerceDisclaimerPreview}
                </button>
                {isDownloading && <CircularProgress size={24} />}
              </div>
              <SignatureBox
                signatureBoxRef={signatureRef}
                submitFunction={onClickSubmitSignedCommerce}
                loading={isSaving}
              />
              { signedError && (
              <Alert severity="error">
                {signedError}
              </Alert>
              )}
              {signatureReady && (
              <>
                {!mainDataSuccess && (
                  <Alert severity="warning">
                    FALTA COMPLETAR LOS DATOS DEL COMERCIO
                  </Alert>
                )}
                {!managerDataSuccess && (
                  <Alert severity="warning">
                    FALTA COMPLETAR DATOS DEL RESPONSABLE DE COMERCIO
                  </Alert>
                )}
                {(!uploadDNIFrontSuccess || !uploadDNIBackSuccess || !uploadDocumentCUITSuccess) && (
                  <Alert severity="warning">
                    FALTA COMPLETAR LA CARGA DE DOCUMENTOS
                  </Alert>
                )}
              </>
              )}
            </>
          )}
        </Form>
      </Formik>
    </>
  );
};

CommerceBoxSignDisclaimer.propTypes = {
  mainDataSuccess: PropTypes.bool.isRequired,
  managerDataSuccess: PropTypes.bool.isRequired,
  uploadDNIFrontSuccess: PropTypes.bool.isRequired,
  uploadDNIBackSuccess: PropTypes.bool.isRequired,
  uploadDocumentCUITSuccess: PropTypes.bool.isRequired,
  setSignDisclaimerSuccess: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default CommerceBoxSignDisclaimer;
