import {
  Box, Grid, Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  getBasicDataCommerce, getStates, getBranchRequestById, updateBranchStatus,
} from '../../api/services';
import i18n from '../../components/common/i18n';
import Loading from '../../components/common/Loading';
import BranchBoxManagerData from './branchBoxManagerData';
import BranchBoxMainData from './branchBoxMainData';
import BranchBoxSellersData from './branchBoxSellersData';
import '../../assets/css/branchFormStyle.css';
import LoadingButton from '../../components/common/LoadingButton';

const branchForm = () => {
  const { idCommerce, idBranch } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const BRANCH_MANAGER_BOX = 'commerce_manager_box';
  const BRANCH_DATA_BOX = 'commerce_data_box';
  const SELLERS_DATA_BOX = 'sellers_data_box';

  const [selectedBox, setSelectedBox] = useState(undefined);

  const selectBox = (box) => {
    setSelectedBox(selectedBox !== box ? box : undefined);
  };

  // branch data completed
  const [branchStatus, setBranchStatus] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [branchCompletedSuccess, setBranchCompletedSuccess] = useState(false);
  const [branchCompletedErrorMessage, setBranchCompletedErrorMessage] = useState();

  // branch data
  const [mainDataSuccess, setMainDataSuccess] = useState(false);
  const [taxIdField, setTaxIdField] = useState('');
  const [commerceFantasyName, setCommerceFantasyName] = useState('');
  const [states, setStates] = useState([]);
  const [provinceField, setProvinceField] = useState('');
  const [cityField, setCityField] = useState('');
  const [postalCodeField, setPostalCodeField] = useState('');
  const [addressNameField, setAddressNameField] = useState('');
  const [addressNumberField, setAddressNumberField] = useState('');
  const [phoneField, setPhoneField] = useState('');
  const [emailField, setEmailField] = useState('');

  // manager data
  const [managerDataSuccess, setManagerDataSuccess] = useState(false);
  const [managerNameField, setManagerNameField] = useState('');
  const [managerLastNameField, setManagerLastNameField] = useState('');
  const [managerDocumentNumberField, setManagerDocumentNumberField] = useState('');
  const [managerPhoneField, setManagerPhoneField] = useState('');
  const [managerEmailField, setManagerEmailField] = useState('');

  // seller data
  const [sellersDataSuccess, setSellersDataSuccess] = useState(false);
  const [sellerList, setSellerList] = useState([]);

  const openPendingForm = () => {
    if (!managerDataSuccess) {
      return selectBox(BRANCH_MANAGER_BOX);
    }
    if (!mainDataSuccess) {
      return selectBox(BRANCH_DATA_BOX);
    }
    return undefined;
  };

  const closeForm = () => {
    selectBox();
    openPendingForm();
  };

  const validateDataBeforeSetMainDataSuccess = (branchData) => {
    if (
      branchData
          && branchData.fantasyName && branchData.fantasyName.length > 0
          && branchData.street && branchData.street.length > 0
          && branchData.number && branchData.number.length > 0
          && branchData.province && branchData.province.length > 0
          && branchData.location && branchData.location.length > 0
          && branchData.zipCode && branchData.zipCode.length > 0
          && branchData.phone && branchData.phone.length > 0
          && branchData.mail && branchData.mail.length > 0) {
      return true;
    }
    return false;
  };

  const validateDataBeforeSetManagerDataSuccess = (managerData) => {
    if (
      managerData
          && managerData.identificationNumber && managerData.identificationNumber.length > 0
          && managerData.firstName && managerData.firstName.length > 0
          && managerData.lastName && managerData.lastName.length > 0
          && managerData.phone && managerData.phone.length > 0
          && managerData.mail && managerData.mail.length > 0) {
      return true;
    }
    return false;
  };

  const preLoadFormData = (branchData) => {
    if (branchData) {
      // Set branch status
      setBranchStatus(branchData.status);

      // Set branch data (first box)
      setAddressNameField(branchData.street);
      setAddressNumberField(branchData.number);
      setPostalCodeField(branchData.zipCode);
      setPhoneField(branchData.phone);
      setEmailField(branchData.mail);
      setProvinceField(branchData.province);
      setCityField(branchData.location);
      setMainDataSuccess(validateDataBeforeSetMainDataSuccess(branchData));

      // Set manager data (second box)
      const managers = branchData.userList?.filter((user) => user.role.includes('MANAGER'));
      if (managers && managers.length > 0) {
        setManagerNameField(managers[0].firstName);
        setManagerLastNameField(managers[0].lastName);
        setManagerDocumentNumberField(managers[0].identificationNumber);
        setManagerEmailField(managers[0].mail);
        setManagerPhoneField(managers[0].phone);
        setManagerDataSuccess(validateDataBeforeSetManagerDataSuccess(managers[0]));
      }

      // Set sellers data (third box)
      const sellers = branchData.userList?.filter((user) => user.role.includes('SELLER'));
      if (sellers && sellers.length > 0) {
        setSellerList(sellers);
        setSellersDataSuccess(true);
      } else {
        setSellerList([]);
        setSellersDataSuccess(false);
      }
    }
  };

  const init = async () => {
    setIsLoading(true);
    try {
      const commerceResponse = await getBasicDataCommerce(idCommerce);
      const commerceData = commerceResponse.data;

      // Get branch data
      let branchData;
      if (idBranch !== undefined) {
        const branchResponse = await getBranchRequestById(idBranch);
        branchData = branchResponse.data;
      }

      // get provinces
      const statesResponse = await getStates();
      const statesData = statesResponse.data;
      setStates(statesData);

      setTaxIdField(commerceData.taxId);
      setCommerceFantasyName(commerceData.fantasyName);

      preLoadFormData(branchData);
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response.status === 404) {
        setErrorMessage(i18n.welcomeCommerceError);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => init(), []);

  const populateDataAlreadyExistMessage = (details) => {
    let message = '';
    if (details?.mails?.length > 0) {
      const mails = details.mails.split(',');
      message += i18n.formBranchDataAlreadyExist.mails;
      for (let i = 0; i < mails.length; i += 1) {
        message = `${message}\n- ${mails[i]}`;
      }
    }

    message += details?.mails?.length > 0 && details?.dnis?.length > 0 ? '\n\n' : '';

    if (details?.dnis?.length > 0) {
      const dnis = details?.dnis?.split(',');
      message += i18n.formBranchDataAlreadyExist.dnis;
      for (let i = 0; i < dnis.length; i += 1) {
        message = `${message}\n- ${dnis[i]}`;
      }
    }

    return message;
  };

  const onClickSendData = async () => {
    setIsSaving(true);
    try {
      const request = {
        status: i18n.branchCompleted,
        comment: '',
      };
      await updateBranchStatus(idBranch, request);
      setBranchCompletedSuccess(true);
      setBranchStatus(i18n.commerceCompleted);
    } catch (error) {
      console.error('There was an error!', error);
      if (error?.response?.data?.code === 'COMMERCE_BRANCH_DATA_ALREADY_EXIST_IN_SAPP') {
        setBranchCompletedErrorMessage(populateDataAlreadyExistMessage(error.response.data.details));
      } else {
        setBranchCompletedErrorMessage(i18n.formBranchCompletedError);
      }
    }
    setIsSaving(false);
  };

  return (
    <>
      {
        errorMessage ? (
          <Box sx={{
            width: '250px', marginTop: '5%', marginLeft: { xs: '5%', sm: '30%', md: '40%' },
          }}
          >
            <div />
            <Stack sx={{ margin: '0!important', width: '100%' }}>
              <Alert severity="error">
                {errorMessage}
              </Alert>
            </Stack>
          </Box>
        ) : (
          isLoading === true ? (
            <Loading />
          ) : (
            isLoading === false && (
            <>
              <Grid container>
                <Grid item md={3} sm={1} />
                <Grid item md={6} sm={10}>
                  <Box mt={8}>
                    <Grid item xs={1} md={2} />
                    {/* branch_data_box */}
                    <Accordion
                      expanded={selectedBox === BRANCH_DATA_BOX}
                      onChange={() => selectBox(BRANCH_DATA_BOX)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{i18n.formBranchesData}</Typography>
                        { mainDataSuccess === true && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <BranchBoxMainData
                          mainDataSuccess={mainDataSuccess}
                          setMainDataSuccess={setMainDataSuccess}
                          taxId={taxIdField}
                          commerceFantasyName={commerceFantasyName}
                          states={states}
                          provinceField={provinceField}
                          cityField={cityField}
                          postalCodeField={postalCodeField}
                          addressNameField={addressNameField}
                          addressNumberField={addressNumberField}
                          phoneField={phoneField}
                          emailField={emailField}
                          closeForm={closeForm}
                        />
                      </AccordionDetails>
                    </Accordion>
                    {/* branch_manager_data_box */}
                    <Accordion
                      expanded={selectedBox === BRANCH_MANAGER_BOX}
                      onChange={() => selectBox(BRANCH_MANAGER_BOX)}
                    >
                      <AccordionSummary
                        disabled={!mainDataSuccess}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>{i18n.formBranchesManager}</Typography>
                        { managerDataSuccess === true && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <BranchBoxManagerData
                          managerDataSuccess={managerDataSuccess}
                          setManagerDataSuccess={setManagerDataSuccess}
                          managerNameField={managerNameField}
                          managerLastNameField={managerLastNameField}
                          managerDocumentNumberField={managerDocumentNumberField}
                          managerPhoneField={managerPhoneField}
                          managerEmailField={managerEmailField}
                          closeForm={closeForm}
                          init={init}
                        />
                      </AccordionDetails>
                    </Accordion>
                    {/* sellers_data_box */}
                    <Accordion
                      expanded={selectedBox === SELLERS_DATA_BOX}
                      onChange={() => selectBox(SELLERS_DATA_BOX)}
                    >
                      <AccordionSummary
                        disabled={!mainDataSuccess}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{i18n.formBranchesSellers}</Typography>
                        { sellersDataSuccess === true && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <BranchBoxSellersData
                          sellersList={sellerList}
                          init={init}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Box mt={5}>
                      <Grid container>
                        <Grid item xs={1} className="comeBackPosition">
                          <Link to={{ pathname: `/listado-sucursales/${idCommerce}` }}>
                            <Button
                              color="primary"
                            >
                              {i18n.formBranchesComeBack}
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item xs={10}>
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            style={{
                              margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
                            }}
                            type="submit"
                            loading={isSaving}
                            disabled={
                              (!(mainDataSuccess) || !(managerDataSuccess))
                              || (branchStatus === i18n.commerceCompleted)
                            }
                            onPress={onClickSendData}
                          >
                            {i18n.formBranchesFinish}
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={1} />
                      </Grid>
                      {branchCompletedSuccess && (
                        <Alert severity="success" style={{ textAlign: 'left' }}>
                          {i18n.formCommerce_signIsSucess}
                        </Alert>
                      )}
                      {branchCompletedErrorMessage && (
                        <Alert severity="error" style={{ textAlign: 'left' }} sx={{ whiteSpace: 'pre-line' }}>
                          {branchCompletedErrorMessage}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={3} sm={1} />
              </Grid>
            </>
            )
          )
        )
      }
    </>
  );
};

export default branchForm;
