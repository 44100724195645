import { createTheme } from '@material-ui/core/styles';

const Theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontSize: '2rem',
      fontWeight: 700,
      color: 'white',
      justifyContent: 'center',
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    list: {
      fontFamily: 'Open sans',
    },
    p: {
      fontFamily: 'Open sans',
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Poppins',
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Open sans',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Poppins',
    },

    fontFamily: 'Open sans',
  },
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      main: '#e72064',
    },
    secondary: {
      main: '#FBB615',
    },
    success: {
      main: '#248E00',
    },
    error: {
      main: '#E20000',
    },
    partial: {
      main: '#FF7C00',
    },
    successlight: {
      main: '#CCEFBB',
    },
    topay: {
      main: '#2165DF',
    },
    whatsapp: {
      main: '#00BB2D',
    },
    lightpink: {
      main: '#FFDCE9',
    },
    detalle: {
      default: '#e91e63',
      main: '#e91e63',
      dark: '#e91e63',
    },

  },
  overrides: {
    MuiContainer: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        padding: '0!important',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '25px',
        textTransform: 'none',
        fontFamily: 'Open sans',
        color: '#e72064',
        marginBottom: '20px',
      },
      contained: {
        borderRadius: '25px',
        backgroundColor: '#E61A5F',
        color: 'white',
        textTransform: 'none',
        fontWeight: '700',
        fontFamily: 'Open sans',
      },
      outlined: {
        borderRadius: '25px',
        backgroundColor: 'white',
        color: '#e72064',
        textTransform: 'none',
        fontWeight: '00',
        fontFamily: 'Open sans',
        width: '100%',
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'normal',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: 'black',
          backgroundColor: 'light gray',
          cursor: 'default',
        },
        '&$selected:hover': {
          color: 'black',
          backgroundColor: '#d6d6d6',
          cursor: 'default',
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'normal',
      },
    },
    MuiTableCell: {
      root: {
        '&:lastChild': { paddingRight: '0px' },
      },
      sizeSmall: {
        padding: '0px',
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    MuiAccordion: {
      root: {
        border: '1px',
      },
    },

    // estilos personalizados de input
    input: {
      '&:-internal-autofill-selected': {
        backgroundColor: 'white!important',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#444444',
        '&$disabled': {
          color: '#6e6e6e',
        },
        '&$focused $notchedOutline': {
          borderColor: '#444444',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        Mui: {
          focused: {
            MuiOutlinedInput: {
              notchedOutline: {
                borderColor: '#E61A5F',
              },
            },
            notchedOutline: {
              borderColor: '#E61A5F',
            },
          },
          // texto ingresado
          MuiInputBase: {
            input: {
              '&$disabled': {
                color: '#444444',
              },
            },
          },

        },

      },
    },

    // fin estilos personalizados de input

  },
  button: {
    card: {
      fontFamily: 'Open sans',
    },
  },
  body: {
    backgroundColor: 'white',
  },
});

export default Theme;
