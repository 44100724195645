const columnsListBranches = [
  {
    name: 'fantasyName',
    label: 'Fantasía',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'headOfficeTaxId',
    label: 'CUIT',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Estado',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'createdDate',
    label: 'Fecha Alta',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'details',
    label: 'Detalle',
    options: {
      filter: false,
      sort: false,
    },
  },
];

export default columnsListBranches;
