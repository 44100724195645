import {
  Box, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from './common/i18n';

const BranchDetailsUserData = ({ userData }) => (
  <Grid container>
    <Grid item xs={12} md={6} className="textLeft">
      <Box ml={5}>
        <Box mt={5}>
          <Typography variant="h5">
            {i18n.detailsUserName}
            <strong>{userData.firstName}</strong>
          </Typography>
        </Box>
        <Box mt={5}>
          <Typography variant="h5">
            {i18n.detailsDocumentNumber}
            <strong>{userData.identificationNumber}</strong>
          </Typography>
        </Box>
        <Box mt={5}>
          <Typography variant="h5">
            {i18n.detailsEmail}
            <strong>{userData.mail}</strong>
          </Typography>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} md={6} className="textLeft">
      <Box mt={5}>
        <Typography variant="h5">
          {i18n.detailsLastName}
          <strong>{userData.lastName}</strong>
        </Typography>
      </Box>
      <Box mt={5}>
        <Typography variant="h5">
          {i18n.detailsCellphone}
          <strong>{userData.phone}</strong>
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

BranchDetailsUserData.propTypes = {
  userData: PropTypes.func.isRequired,
};

export default BranchDetailsUserData;
