import {
  Box,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploadFile } from '../../api/services';
import i18n from '../../components/common/i18n';
import TextFieldWrapper from '../../components/common/TextFieldWrapper';
import { translateErrorOrDefault } from '../../utils/functionsUtil';

const CommerceBoxUploadDocuments = ({
  uploadDNIFrontSuccess, uploadDNIBackSuccess, uploadDocumentCUITSuccess,
  setUploadDNIFrontSuccess, setUploadDNIBackSuccess, setUploadDocumentCUITSuccess, closeForm,
}) => {
  const { idCommerce } = useParams();

  const DNI_FRENTE = 'DNI_FRENTE';
  const DNI_DORSO = 'DNI_DORSO';
  const CONSTANCIA_CUIT = 'CONSTANCIA_CUIT';

  const [uploadDNIFrontError, setUploadDNIFrontError] = useState(undefined);
  const [uploadDNIBackError, setUploadDNIBackError] = useState(undefined);
  const [uploadDocumentCUITError, setUploadDocumentCUITError] = useState(undefined);
  const [isLoadingFront, setIsLoadingFront] = useState(false);
  const [isLoadingBack, setIsLoadingBack] = useState(false);
  const [isLoadingCuil, setIsLoadingCuil] = useState(false);

  const handleUploadFile = async (event, type, setUploadSuccess, setUploadError, setLoading) => {
    setLoading(true);
    const { files } = event.currentTarget;
    if (files && files[0]) {
      try {
        await uploadFile(idCommerce, type, files[0]);
        setUploadSuccess(true);
        setUploadError(false);
        if ((uploadDNIFrontSuccess === true || type === DNI_FRENTE)
          && (uploadDNIBackSuccess === true || type === DNI_DORSO)
          && (uploadDocumentCUITSuccess === true || type === CONSTANCIA_CUIT)
        ) {
          closeForm();
        }
      } catch (error) {
        console.error('There was an error!', error);
        setUploadError(translateErrorOrDefault(error.response.data?.code, i18n.formCommerceErrorUploadDocument));
      }
    }
    setLoading(false);
  };

  const handleUploadDNIFront = (event) => {
    handleUploadFile(event, DNI_FRENTE, setUploadDNIFrontSuccess, setUploadDNIFrontError, setIsLoadingFront);
  };

  const handleUploadDNIBack = (event) => {
    handleUploadFile(event, DNI_DORSO, setUploadDNIBackSuccess, setUploadDNIBackError, setIsLoadingBack);
  };

  const handleUploadDocumentCUIT = (event) => {
    handleUploadFile(event,
      CONSTANCIA_CUIT,
      setUploadDocumentCUITSuccess,
      setUploadDocumentCUITError,
      setIsLoadingCuil);
  };

  return (
    <>
      <Formik>
        <Form>
          <Box mt={2}>
            {isLoadingFront ? (
              <CircularProgress />
            ) : (
              uploadDNIFrontSuccess ? (
                <>
                  {uploadDNIFrontError ? (
                    <>
                      <Alert severity="warning">
                        <Typography>{i18n.formCommerceUploadDocumentDNIFront}</Typography>
                      </Alert>
                      <Alert severity="error">
                        <Typography>{uploadDNIFrontError}</Typography>
                      </Alert>
                    </>
                  ) : (
                    <Alert severity="success">
                      <Typography>{i18n.formCommerceUploadDocumentDNIFront}</Typography>
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  {uploadDNIFrontError ? (
                    <Alert severity="error">
                      <Typography>{i18n.formCommerceUploadDocumentDNIFront}</Typography>
                    </Alert>
                  ) : (
                    <Typography>{i18n.formCommerceUploadDocumentDNIFront}</Typography>
                  )}
                </>
              )
            )}
            <TextFieldWrapper
              margin="normal"
              fullWidth
              name="uploadDocumentDNIFront"
              onChange={handleUploadDNIFront}
              accept="image/png, image/jpeg, application/pdf"
              type="file"
              disabled={isLoadingFront}
              helperText={i18n.formCommerceUploadDocumentDNIFrontHelper}
            />
          </Box>
          <Box mt={2}>
            {isLoadingBack ? (
              <CircularProgress />
            ) : (
              uploadDNIBackSuccess ? (
                <>
                  {uploadDNIBackError ? (
                    <>
                      <Alert severity="warning">
                        <Typography>{i18n.formCommerceUploadDocumentDNIBack}</Typography>
                      </Alert>
                      <Alert severity="error">
                        <Typography>{uploadDNIBackError}</Typography>
                      </Alert>
                    </>
                  ) : (
                    <Alert severity="success">
                      <Typography>{i18n.formCommerceUploadDocumentDNIBack}</Typography>
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  {uploadDNIBackError ? (
                    <Alert severity="error">
                      <Typography>{i18n.formCommerceUploadDocumentDNIBack}</Typography>
                    </Alert>
                  ) : (
                    <Typography>{i18n.formCommerceUploadDocumentDNIBack}</Typography>
                  )}
                </>
              )
            )}
            <TextFieldWrapper
              margin="normal"
              fullWidth
              name="uploadDocumentDNIBack"
              onChange={handleUploadDNIBack}
              accept="image/png, image/jpeg, application/pdf"
              type="file"
              disabled={isLoadingBack}
              helperText={i18n.formCommerceUploadDocumentDNIBackHelper}
            />
          </Box>
          <Box mt={2}>
            {isLoadingCuil ? (
              <CircularProgress />
            ) : (
              uploadDocumentCUITSuccess ? (
                <>
                  {uploadDocumentCUITError ? (
                    <>
                      <Alert severity="warning">
                        <Typography>{i18n.formCommerceUploadDocumentCUIT}</Typography>
                      </Alert>
                      <Alert severity="error">
                        <Typography>{uploadDocumentCUITError}</Typography>
                      </Alert>
                    </>
                  ) : (
                    <Alert severity="success">
                      <Typography>{i18n.formCommerceUploadDocumentCUIT}</Typography>
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  {uploadDocumentCUITError ? (
                    <Alert severity="error">
                      {i18n.formCommerceUploadDocumentCUIT}
                    </Alert>
                  ) : (
                    <Typography>{i18n.formCommerceUploadDocumentCUIT}</Typography>
                  )}
                </>
              )
            )}
            <TextFieldWrapper
              margin="normal"
              fullWidth
              name="uploadDocumentCUIT"
              onChange={handleUploadDocumentCUIT}
              accept="image/png, image/jpeg, application/pdf"
              type="file"
              disabled={isLoadingCuil}
              helperText={i18n.formCommerceUploadDocumentCUITHelper}
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
};

CommerceBoxUploadDocuments.propTypes = {
  uploadDNIFrontSuccess: PropTypes.bool.isRequired,
  uploadDNIBackSuccess: PropTypes.bool.isRequired,
  uploadDocumentCUITSuccess: PropTypes.bool.isRequired,
  setUploadDNIFrontSuccess: PropTypes.func.isRequired,
  setUploadDNIBackSuccess: PropTypes.func.isRequired,
  setUploadDocumentCUITSuccess: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default CommerceBoxUploadDocuments;
