import { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const UserDataContext = createContext(null);

export const UserDataProvider = ({ data, children }) => {
  const [userData, setUserData] = useState(data);

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

UserDataProvider.propTypes = {
  data: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

UserDataProvider.defaultProps = {
  data: null,
};

export const useUserData = () => useContext(UserDataContext);
