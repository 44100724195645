import { useState, useEffect } from 'react';
import {
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import i18n from '../../components/common/i18n';
import LoadingButton from '../../components/common/LoadingButton';
import SellerData from './sellerData';

const branchBoxSellersData = ({ sellersList, init }) => {
  const SELLERS_DATA_BOX = 'sellers_data_box';
  const [selectedBox, setSelectedBox] = useState(undefined);
  const [errorAddSeller, setErrorAddSeller] = useState(false);
  const [defaultForm, setDefaultForm] = useState(false);
  const [sellerDataSuccess, setSellerDataSuccess] = useState(true);
  const [newSeller, setNewSeller] = useState(false);

  const selectBox = (box) => {
    setSelectedBox(selectedBox !== box ? box : undefined);
  };

  const closeForm = () => {
    selectBox();
  };

  const checkSellersCreated = () => {
    let sellers = false;
    if (sellersList && sellersList.length > 0) {
      sellers = true;
    }
    return sellers;
  };

  const openDefaultForm = () => {
    if (!checkSellersCreated()) {
      setDefaultForm(true);
    }
  };

  const addNewSeller = () => {
    setNewSeller(true);
    if (!checkSellersCreated()) {
      setErrorAddSeller(true);
    }
  };

  useEffect(() => {
    openDefaultForm();
  }, []);

  return (
    <>
      { defaultForm ? (
        <Accordion
          expanded={selectedBox === SELLERS_DATA_BOX}
          onChange={() => selectBox(SELLERS_DATA_BOX)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{i18n.formSeller}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SellerData
              sellerDataSuccess={false}
              setSellerDataSuccess={setSellerDataSuccess}
              closeForm={closeForm}
              init={init}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        sellersList.map((seller) => (
          <Accordion
            expanded={selectedBox === seller.identificationNumber}
            onChange={() => selectBox(seller.identificationNumber)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {`${seller.firstName.toUpperCase()} ${seller.lastName.toUpperCase()}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SellerData
                sellerDataSuccess={sellerDataSuccess}
                sellerNameField={seller.firstName}
                sellerLastNameField={seller.lastName}
                sellerDocumentNumberField={seller.identificationNumber}
                sellerPhoneField={seller.phone}
                sellerEmailField={seller.mail}
                closeForm={closeForm}
                init={init}
              />
            </AccordionDetails>
          </Accordion>
        ))
      )}
      {newSeller && (
        <Accordion
          expanded={selectedBox === SELLERS_DATA_BOX}
          onChange={() => selectBox(SELLERS_DATA_BOX)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{i18n.formSeller}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SellerData
              sellerDataSuccess={false}
              setSellerDataSuccess={setSellerDataSuccess}
              closeForm={closeForm}
              init={init}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <LoadingButton
        variant="contained"
        color="primary"
        style={{
          margin: '0!important', marginLeft: '20%', marginRight: '20%', width: '100%',
        }}
        type="submit"
        disabled={!checkSellersCreated()}
        onPress={addNewSeller}
      >
        {i18n.formBranchesAddSeller}
      </LoadingButton>
      { errorAddSeller && (
        <Alert severity="error">
          {i18n.formSellerAddError}
        </Alert>
      )}
    </>
  );
};

branchBoxSellersData.propTypes = {
  sellersList: PropTypes.arrayOf(Object).isRequired,
  init: PropTypes.func.isRequired,
};

export default branchBoxSellersData;
