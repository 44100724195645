import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    minWidth: 120,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
  },
  disabledButton: {
    backgroundColor: 'white',
  },
  secondary: {
    borderRadius: '25px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    fontWeight: '700',
  },
  link: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    minWidth: 120,
  },
}));

const LoadingButton = ({
  children, onPress, loading, type, secondary, link, style, disabled, startIcon,
}) => {
  const classes = useStyles();
  const customClass = secondary ? classes.secondary : classes.link;
  const buttonClass = secondary || link ? customClass : classes.button;

  return (
    <div className={classes.wrapper}>
      <Button
        startIcon={startIcon}
        style={style}
        onClick={onPress}
        className={buttonClass}
        disabled={loading || disabled}
        type={type}
        classes={{
          disabled: classes.disabledButton,
        }}
        variant="contained"
        color="primary"
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

LoadingButton.propTypes = {
  onPress: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  link: PropTypes.bool,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  startIcon: PropTypes.element,
};

LoadingButton.defaultProps = {
  onPress: undefined,
  type: 'button',
  secondary: false,
  link: false,
  style: undefined,
  loading: false,
  disabled: false,
  startIcon: null,
};

export default LoadingButton;
