import {
  Box, FormControl, Grid, InputLabel, MenuItem, Select, makeStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PageviewIcon from '@mui/icons-material/Pageview';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { Link, useHistory, useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import toast, { Toaster } from 'react-hot-toast';
import { COMMERCE_WEB_APP, getEnv } from '../api/env';
import {
  getCommerceRequestAfilliationList, getNosisFile, getPublicUrlImage, saveCommerceAfilliationInSapp,
  addNote,
  getProductList,
  getSubproductList,
  saveProductDetailList,
} from '../api/services';
import ChangeStateBox from '../components/changeStateBox';
import i18n from '../components/common/i18n';
import Loading from '../components/common/Loading';
import LoadingButton from '../components/common/LoadingButton';
import Toast from '../components/common/Toast';
import NoteBox from '../components/NoteBox';
import TrackTraceViewer from '../components/TrackTraceViewer';
import Columns from '../constants/columns/columnsCommentsDetails';
import { orderedDataList } from '../constants/listDetails';
import { route } from '../constants/routes';
import { useUserData } from '../contexts/userDataContext';
import { fillMessageWith, translateErrorOrDefault } from '../utils/functionsUtil';
import { statusMapper } from '../utils/statusMapper';

const useStyles = makeStyles(() => ({
  h3: {
    fontWeight: 'lighter',
    textAlign: 'left',
    marginLeft: '2%',
    marginRight: '2%',
  },
  h2: {
    paddingTop: '1%',
  },
  productRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const commerceDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userData } = useUserData();
  const [rol] = useState(userData.roles);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtnNosis, setIsLoadingBtnNosis] = useState(false);
  const [loadingAddNote, setLoadingAddNote] = useState(false);
  const [isLoadingSaveProduct, setIsLoadinSaveProduct] = useState(false);
  const [commerceData, setCommerceData] = useState(undefined);
  const [commerceStatus, setCommerceStatus] = useState('');
  const webAppUrl = getEnv(COMMERCE_WEB_APP);
  const [refreshPage, setRefreshPage] = useState(false);
  const [dataTable, setDataTable] = useState([{}]);
  const [decisionEngineResponse, setDecisionEngineResponse] = useState([]);
  const [exceptionBox, setExceptionBox] = useState(true);
  const [showButtonSaveInSapp, setShowButtonSaveInSapp] = useState(false);
  const [showSuccessSaveInSapp, setShowSuccessSaveInSapp] = useState(false);
  const [detailsSaveInSappError, setDetailsSaveInSappError] = useState(undefined);
  const [addNoteError, setAddNoteError] = useState(false);
  const [addNoteSuccess, setAddNoteSuccess] = useState(false);
  const [productList, setProductList] = useState(['']);
  const [subproductList, setSubproductList] = useState(['']);
  const [termList, setTermList] = useState(['']);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedSubproduct, setSelectedSubproduct] = useState('');
  const [selectedTerm, setSelectedTerm] = useState('');
  const [productDetailList, setProductDetailList] = useState([]);

  const handleClickOpenCopyMessage = () => {
    navigator.clipboard.writeText(`${webAppUrl}/listado-sucursales/${commerceData.id}`);
    toast.success(i18n.copyToClipboard);
  };

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: false,
    print: false,
  };

  const { idCommerce } = useParams();

  const changeRefreshPageHandler = (value) => {
    setRefreshPage(value);
  };

  const noteListFilter = (notes) => {
    const notesFilter = notes;
    setDataTable([{}]);
    for (let i = 0; i < notesFilter.length; i += 1) {
      if (notesFilter[i].comment.length > 0) {
        if (notesFilter[i].type === i18n.detailsDecisionEngineUser) {
          notesFilter[i].user = i18n.detailsDecisionEngine;
        }
        notesFilter[i].type = statusMapper(notes[i].type);
        setDataTable((array) => [...array, notesFilter[i]]);
        if (notesFilter[i].type === i18n.commerceException) {
          setExceptionBox(false);
        }
      }
    }
  };

  const downloadArchiveHandler = async () => {
    try {
      setIsLoadingBtnNosis(true);
      const { data } = await getNosisFile(commerceData.taxId);
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = `Nosis_${commerceData.taxId}.html`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setIsLoadingBtnNosis(false);
    }
  };

  const showImage = async (filename) => {
    try {
      const { data } = await getPublicUrlImage(commerceData.id, filename);
      window.open(data, '_blank');
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const onClickSaveInSapp = async () => {
    setIsLoading(true);
    try {
      await saveCommerceAfilliationInSapp(idCommerce, userData.sub);
      setShowButtonSaveInSapp(false);
      setShowSuccessSaveInSapp(true);
    } catch (error) {
      console.error('There was an error!', error);
      setDetailsSaveInSappError(translateErrorOrDefault(error.response.data?.code,
        fillMessageWith(i18n.COMMERCE_AFFILIATION_REQUEST_ERROR_SAVING_IN_SAPP, error.response.data?.message)));
    }
    setIsLoading(false);
  };

  const handleAddNoteSubmit = async ({ comment }) => {
    try {
      setAddNoteError(false);
      setLoadingAddNote(true);
      await addNote(commerceData.id, comment);
      setAddNoteSuccess(true);
      changeRefreshPageHandler(true);
    } catch (error) {
      setAddNoteError(true);
    } finally {
      setLoadingAddNote(false);
    }
  };

  const handleCloseToast = () => {
    setAddNoteError(false);
    setAddNoteSuccess(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    try {
      const { data } = await getCommerceRequestAfilliationList(idCommerce);
      setCommerceData(data);
      setCommerceStatus(data.status);
      noteListFilter(data.noteList);
      setDecisionEngineResponse(data.decisionEngineVariables);
      setRefreshPage(false);
      setShowButtonSaveInSapp(data.status === i18n.commerceCompleted && rol.includes(i18n.rolAdmin));
      setShowSuccessSaveInSapp(data.status === i18n.commerceFinished);
      if ((data.status === i18n.commerceApproved
        || data.status === i18n.commerceCompleted
        || data.status === i18n.commerceFinished)) {
        const productListResponse = await getProductList();
        setProductList(productListResponse.data);
      }
      if (data.commerceData?.productDetail) {
        setProductDetailList(data.commerceData.productDetail);
      }
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response.status === 404) {
        history.push(route.newCommerce);
      }
    }
    setIsLoading(false);
  }, [refreshPage]);

  const resolveContactLabel = (contactType) => {
    switch (contactType) {
      case 'EMAIL': return i18n.detailsEmail;
      case 'INSTAGRAM': return i18n.detailsInstagram;
      case 'WEBSITE': return i18n.detailsWebSite;
      default: return '';
    }
  };

  const handleOnChangeProduct = async (event) => {
    const product = event.target.value;
    setSelectedProduct(product);
    const subproductListResponse = await getSubproductList(product.id);
    setSelectedSubproduct('');
    setSelectedTerm('');
    setSubproductList(['', ...subproductListResponse.data]);
    setTermList(['']);
  };

  const handleOnChangeSubproduct = async (event) => {
    const subproduct = event.target.value;
    setSelectedSubproduct(subproduct);
    setSelectedTerm('');
    setTermList(['', ...i18n.termsList]);
  };

  const handleOnChangeTerm = async (event) => {
    const term = event.target.value;
    setSelectedTerm(term);
  };

  const handleOnClickAddProduct = () => {
    console.log({ selectedProduct, selectedSubproduct, selectedTerm });
    setProductDetailList([
      ...productDetailList,
      { product: selectedProduct, subproduct: selectedSubproduct, term: selectedTerm },
    ]);
  };

  const removeProduct = (index) => {
    const auxList = [...productDetailList];
    auxList.splice(index, 1);
    setProductDetailList(auxList);
  };

  const saveProductDetail = async () => {
    try {
      setIsLoadinSaveProduct(true);
      await saveProductDetailList(commerceData.id, productDetailList);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadinSaveProduct(false);
    }
  };

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && commerceData && (
        <>
          <Box mt={8}>
            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={10} md={8}>
                <div style={{ border: '1px solid #b1a7a6' }}>
                  <h2 className={classes.h2}>{i18n.detailsCommerceTitle}</h2>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <h3 className={classes.h3}>
                        {i18n.detailsBussinessName}
                        <strong>{commerceData.name}</strong>
                      </h3>
                      <h3 className={classes.h3}>
                        {i18n.detailsName}
                        <strong>{commerceData.fantasyName}</strong>
                      </h3>
                      <h3 className={classes.h3}>
                        {i18n.detailsCuit}
                        <strong>{commerceData.taxId}</strong>
                      </h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {commerceData.contactData.map((contact) => (
                        <h3 className={classes.h3}>
                          {resolveContactLabel(contact.type)}
                          <strong>{contact.value || '-'}</strong>
                        </h3>
                      ))}
                      <h3 className={classes.h3}>
                        {i18n.detailsComments}
                        <strong>{commerceData.noteList[0].comment ? commerceData.noteList[0].comment : '-'}</strong>
                      </h3>
                      <h3 className={classes.h3}>
                        {i18n.detailsCreatedBy}
                        <strong>{commerceData.createdBy}</strong>
                      </h3>
                    </Grid>
                    {(
                      commerceData.status === i18n.commerceApproved
                      || (commerceData.status === i18n.commerceCompleted && !showSuccessSaveInSapp)
                    ) && (
                      <Grid item xs={12} md={12}>
                        <h3 className={classes.h3} style={{ marginLeft: '1%' }}>
                          {i18n.detailsCommerceFormLink}
                          <Link
                            to={{ pathname: `/bienvenida-comercio/${commerceData.id}` }}
                          >
                            {`${webAppUrl}/bienvenida-comercio/${commerceData.id}`}
                          </Link>
                        </h3>
                      </Grid>
                    )}
                    {
                      commerceData.status === i18n.commerceFinished && (
                        <Grid container>
                          <Grid item xs={9}>
                            <h3 className={classes.h3} style={{ marginLeft: '1%' }}>
                              {i18n.detailsCommerceBranchFormLink}
                              <Link
                                to={{ pathname: `/listado-sucursales/${commerceData.id}` }}
                              >
                                {`${webAppUrl}/listado-sucursales/${commerceData.id}`}
                              </Link>
                            </h3>
                          </Grid>
                          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ContentCopyIcon onClick={handleClickOpenCopyMessage} style={{ cursor: 'pointer' }} />
                            <Toaster
                              position="bottom-center"
                              reverseOrder={false}
                            />
                          </Grid>
                        </Grid>
                      )
                    }
                    {(commerceStatus === i18n.commerceCompleted || commerceStatus === i18n.commerceFinished)
                      && commerceData.commerceData ? (
                        <>
                          <Grid item xs={12} md={12}>
                            <h3 className={classes.h2}>{i18n.detailsCommerceDataTitle}</h3>
                          </Grid>
                          {
                            orderedDataList.map((key) => (
                              <Grid item xs={12} md={6} key={key}>
                                <h3 className={classes.h3}>
                                  <Typography>
                                    {`${i18n[`formCommerce_${key}`]}: `}
                                    <strong>{commerceData.commerceData[key]}</strong>
                                  </Typography>
                                </h3>
                              </Grid>
                            ))
                          }
                        </>
                      ) : null}
                    <Grid item xs={12} md={12} />
                    {(commerceStatus === i18n.commerceCompleted || commerceStatus === i18n.commerceFinished)
                      && commerceData.documentList ? (
                        <>
                          <Grid item xs={12} md={12}>
                            <h3 className={classes.h2}>{i18n.detailsCommerceImagesTitle}</h3>
                          </Grid>
                          {commerceData.documentList.map((document) => (
                            <Grid item xs={12} md={6}>
                              <h3 className={classes.h3}>
                                <Typography>
                                  {document.type}
                                  <IconButton
                                    style={{ color: '#E61A5F' }}
                                    component="span"
                                    onClick={() => showImage(document.name)}
                                  >
                                    <PageviewIcon />
                                  </IconButton>
                                </Typography>
                              </h3>
                            </Grid>
                          ))}
                        </>
                      ) : null}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={1} md={2} />
            </Grid>
          </Box>

          {detailsSaveInSappError ? (
            <Box mt={5}>
              <Grid container>
                <Grid item xs={1} md={2} />
                <Grid item xs={10} md={8}>
                  <Alert severity="error">
                    {detailsSaveInSappError}
                  </Alert>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Box>
          ) : showButtonSaveInSapp ? (
            <Box mt={5}>
              <Grid container>
                <Grid item xs={1} md={2} />
                <Grid item xs={10} md={8}>
                  <Button variant="contained" onClick={onClickSaveInSapp}>{i18n.detailsSaveInSapp}</Button>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Box>
          ) : (
            showSuccessSaveInSapp && (
              <Box mt={5}>
                <Grid container>
                  <Grid item xs={1} md={2} />
                  <Grid item xs={10} md={8}>
                    <Alert severity="success">
                      {i18n.detailsSaveInSappSuccess}
                    </Alert>
                  </Grid>
                  <Grid item xs={1} md={2} />
                </Grid>
              </Box>
            )
          )}

          {/* ---------- BLOQUE DE CARGA DE DETALLE DE PRODUCTO ---------- */}
          {(commerceStatus === i18n.commerceApproved
            || commerceStatus === i18n.commerceCompleted
            || commerceStatus === i18n.commerceFinished) && (
              <Box mt={10}>
                <Grid container>
                  <Grid item xs={1} md={2} />
                  <Grid item xs={10} md={8}>
                    <div style={{ border: '1px solid #b1a7a6' }}>
                      <h2 className={classes.h2}>{i18n.productDetailTitle}</h2>
                      <Box style={{ padding: '20px' }}>
                        <Grid container spacing={2} alignItems="flex-end">
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel id="label-product">{i18n.productLabel}</InputLabel>
                              <Select
                                labelId="label-product"
                                onChange={handleOnChangeProduct}
                                value={selectedProduct}
                              >
                                { productList.map((product) => (
                                  <MenuItem value={product} key={product.id}>{product.description}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel id="label-subproduct">{i18n.subproductLabel}</InputLabel>
                              <Select
                                labelId="label-subproduct"
                                onChange={handleOnChangeSubproduct}
                                value={selectedSubproduct}
                              >
                                { subproductList.map((subproduct) => (
                                  <MenuItem value={subproduct} key={subproduct.id}>
                                    {subproduct.description}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel id="label-term">{i18n.termLabel}</InputLabel>
                              <Select
                                labelId="label-term"
                                onChange={handleOnChangeTerm}
                                value={selectedTerm}
                              >
                                { termList.map((term) => (
                                  <MenuItem value={term} key={term}>
                                    {term}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Button
                              style={{ margin: '0px' }}
                              variant="contained"
                              onClick={handleOnClickAddProduct}
                              disabled={selectedProduct === '' || selectedSubproduct === '' || selectedTerm === ''}
                            >
                              {i18n.addProductCTA}
                            </Button>
                          </Grid>
                        </Grid>
                        <Box mt={4} />
                        <Grid container>
                          <Grid item xs={3}><b>{i18n.productLabel}</b></Grid>
                          <Grid item xs={3}><b>{i18n.subproductLabel}</b></Grid>
                          <Grid item xs={3}><b>{i18n.termLabel}</b></Grid>
                          <Grid item xs={3} />
                          <Grid item xs={12}><hr /></Grid>
                          {productDetailList.map((detail, index) => (
                            <>
                              <Grid item xs={3}>
                                <Box className={classes.productRow}>
                                  {detail.product.description}
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <Box className={classes.productRow}>
                                  {detail.subproduct.description}
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <Box className={classes.productRow}>
                                  {detail.term}
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <Box className={classes.productRow} sx={{ textAlign: 'left' }}>
                                  <IconButton
                                    style={{ color: 'rgb(203 23 23)' }}
                                    component="span"
                                    onClick={() => removeProduct(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                              <Grid item xs={12}><hr /></Grid>
                            </>
                          ))}
                        </Grid>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '20px' }}>
                          <LoadingButton
                            onPress={saveProductDetail}
                            loading={isLoadingSaveProduct}
                            style={{ margin: 'auto' }}
                          >
                            {i18n.saveProductCTA}
                          </LoadingButton>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Box>
          )}

          <Box mt={10}>
            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={10} md={8}>
                <div style={{ border: '1px solid #b1a7a6' }}>
                  <h2 className={classes.h2}>{i18n.detailsRiskMotorTitle}</h2>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <h3 className={classes.h3}>
                        {i18n.detailsState}
                        <strong>{statusMapper(commerceData.status)}</strong>
                      </h3>
                      <h3 className={classes.h3}>
                        {i18n.detailsMotive}
                        <strong>{commerceData.rejectedDescription ? commerceData.rejectedDescription : '-'}</strong>
                      </h3>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h3 className={classes.h3}>
                        {i18n.detailsCode}
                        <strong>{commerceData.rejectedCode ? commerceData.rejectedCode : '-'}</strong>
                      </h3>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={1} md={2} />
            </Grid>
          </Box>

          <Box mt={10}>
            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={10} md={8}>
                <div style={{ border: '1px solid #b1a7a6' }}>
                  <h2 className={classes.h2}>{i18n.detailsResponseMotorTitle}</h2>
                  <Collapsible trigger={i18n.viewDetailsButton}>
                    <TrackTraceViewer trackTrace={decisionEngineResponse} />
                  </Collapsible>
                </div>
              </Grid>
              <Grid item xs={1} md={2} />
            </Grid>
          </Box>

          <Box mt={5}>
            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={10} md={8}>
                <LoadingButton onPress={downloadArchiveHandler} loading={isLoadingBtnNosis} style={{ margin: 'auto' }}>
                  {i18n.detailsDownloadNosis}
                </LoadingButton>
              </Grid>
              <Grid item xs={1} md={2} />
            </Grid>
          </Box>

          <Box my={10}>
            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={10} md={8}>
                <MUIDataTable
                  title={i18n.detailsCommentsTitle}
                  data={dataTable}
                  columns={Columns}
                  options={options}
                />
              </Grid>
              <Grid item xs={1} md={2} />
            </Grid>
          </Box>

          <Grid container>
            <Grid item xs={1} md={2} />
            <Grid item xs={10} md={8}>
              <NoteBox
                onSubmit={handleAddNoteSubmit}
                loading={loadingAddNote}
              />
            </Grid>
          </Grid>
          {addNoteSuccess && (
            <Toast
              messageOnSuccess={i18n.noteBoxMessageSuccess}
              open
              onClose={handleCloseToast}
            />
          )}
          {addNoteError && (
            <Toast
              messageOnError={i18n.noteBoxMessageError}
              open
              onClose={handleCloseToast}
            />
          )}

          { /* modificar el rol como me llega. Agregar: && commerceData.rejectedCode === 'OBSERVADO' */}
          {(rol.includes(i18n.rolAdmin) && commerceStatus !== i18n.commerceApproved
            && commerceStatus !== i18n.commerceCompleted && commerceStatus !== i18n.commerceFinished)
            || (rol.includes(i18n.rolCommercial)
              && commerceStatus === i18n.commerceRejected
              && commerceData.rejectedCode === i18n.commerceCodeObserved
              && exceptionBox)
            ? (
              <ChangeStateBox
                commerceStatus={commerceStatus}
                refresh={changeRefreshPageHandler}
                commerceId={idCommerce}
              />
            )
            : null}
        </>
      )}
    </>
  );
};

export default commerceDetails;
