const columnsCommentsDetails = [
  {
    name: 'comment',
    label: 'Comentario',
  },
  {
    name: 'user',
    label: 'Usuario',
  },
  {
    name: 'date',
    label: 'Fecha',
  },
  {
    name: 'type',
    label: 'Tipo',
  },
];

export default columnsCommentsDetails;
